.mainContainer {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.Container{
    margin: 0 auto;
    max-width: 68.375rem;
}
.ContainerBox{
    width: 65%;
}
.Boxes{
    box-shadow: 0 10px 30px 0 rgba(127, 127, 127, 0.3);
    background: #fff;
    border-radius: 10px;
    width: 100%;
}
.ChildDiv{
    width: 35%;
    border-right: 1px solid lightgray;
}
.ChildDivP{
    color: #6D7175;
    font-size: 15px;
    margin: 0px 0px 5px 5px;
}
.HighlightColor{
    background-color:#FFD79D;
    padding: 2px 10px;
    border-radius: 15px;
    font-size: 12px;
    display: flex;
    margin-right: 10px;
}

.BorderRadius{
    width: 8px;
    height: 8px;
    margin-top: 6px;
    margin-right: 5px;
    border-radius: 10px;
    border: 2px solid #b98900;
}

.Aside{
    width: 34%;
}
.Qty{
    position: absolute;
    left: 29px;
    bottom: -13px;
    background-color: lightgray;
    padding: 0px 8px;
    border-radius: 11px;
    font-size: 14px;
}
.OrderBoxes{
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid lightgray;
    padding: 0px 20px 10px;
}

.box {
    display: flex;
    justify-content: space-between;
}

.boxHeader{
    margin-top: 15px;
    display: flex;
}

.OrderName {
    color: #2C6ECB;
    font-weight: 600;
    margin-right: 10px;
}

.createOrderBtn {
    display: flex;
    justify-content: flex-end;
    padding: 20px;
}

.createOrderButton {
    background-color: #008060;
    padding: 7px 16px;
    border: 0;
    color: #FFF;
    border-radius:5px;
}

.ProductImages {
    width: 40px;
    height: 40px;
    border: 1px solid lightgray;
    border-radius: 8px;
}

.CartIcon {
    display: flex;
    justify-content: center;
    margin-top: 30px;
}

.NotExistOrder {
    display: flex;
    text-align: center;
    justify-content: center;
}

.myCustomerDetailContainer {
    width: 70%;
}

.myCustomerAside {
    width: 28%;
}

.meetingCancelled {
    background-color: #EB3A10;
    color: white;
    border-radius: 10px;
    padding: 2px 6px 0px;
    font-size: 13px;
    text-align: center;
    width: fit-content;
    margin: auto;
}

@media screen and (max-width: 1044px){
    .ContainerBox{
        width: 100%;
        margin-bottom: 16px;
    }
    .myCustomerDetailContainer {
        width: 100%;
        margin-bottom: 16px;
    }
    .myCustomerAside {
        width: 100%;
    }
    .Aside{
        width: 100%;
    }
}

.responsiveTable {
    display: none;
}

.responsiveTableBox {
    padding:20px;
    box-shadow: rgba(127, 127, 127, 0.3) 0px 0px 4px 2px;
    border-radius: 6px;
    margin: 15px 0px;
}

.SalesMonth {
    width: 18%;
}

@media screen and (max-width:685px){
    .hideTable {
        display: none !important;
    }

    .responsiveTable {
        display: block;
    }
    .SalesMonth {
        width: 40%;
    }
}

@media screen and (max-width:500px){
    .responsiveTable {
        font-size: 14px;
    }

    .TotalOrders {
        margin-top: 26px;
    }
}