.Table thead{
    background-color: #243750 ;
    color: #fff;
    font-size: 16px;
}

.Table > :not(:first-child) {
    border-top: 0;
}


.ReloadBtn{
    display: flex;

}
.TableMobile{
    display: none;
}

@media only screen and (max-width: 768px) {
    .TableMobile{
        display: block;
    }
    .ReloadBtn{
        display: none;
        /* justify-content: end;
        width: 100%;
        margin-bottom: 10px; */
    }
    .Table{
        display: none;
    }
}