.container{
    margin: 0 auto;
    max-width: 62.375rem;
}
.mainContainer{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 14px;
}
.containBoxes{
    width: 66%;
    margin-bottom: 15px;
}
.containerBox{
    padding: 1.1rem;
}
.Box{
    width: 100%;
    border-radius: 8px;
    margin-bottom: 1.25rem;
    padding: 1.1rem;
    box-shadow:0 0 0.3152rem rgba(0, 0, 0, 0.181), 0 0 0.3152rem rgba(0, 0, 0, 0.181);  
}


.AddProductHeader {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.5rem;
}


.AddProductHeaderContent{
    display: flex;
    align-items: center;
    gap: 1rem;
}
.AddProductBtn {
    font-size: 21px;
    font-weight: 600;
}

.UpdateBtn {
    border:  1px solid black;
    background-color: transparent;
    border-radius: 4px;
    padding: 5px 10px;
    font-weight: 600;
    font-size: 15px;
}
.UsersHead{
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.TableHead{
    width: 100%;
    background-color: #243750;
    padding: 10px 0px;
    font-size: 1rem;
    font-weight: 500;
    color: #fff;
    text-transform: uppercase;
}

.TableBody{
    font-size: 0.9rem;
}