.Button:focus{
    box-shadow: 0px 0px 4px 4px lightblue;
}

.Row{
    display: flex;
    justify-content: space-between;
    padding: 12px;
    border-bottom: 1px solid lightgray;
}
.Row:hover{
    background-color: #F6F6F7;
}

.searchContainer{
    display: flex;
    justify-content: space-between;
}
.ExportButton button{
    background-color: green;
    border: 1px solid green;
    border-radius: 4px;
    padding: 7px 5px 6px;
    width: 100%;
    font-size:14px;
    color:white;
    font-weight: 600;
    font-size:15px;
    margin-right:10px;
    width: 119px;
}

.searchField{
    width: 98%;
    padding: 0.375rem 0.75rem 0.375rem 4px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-clip: padding-box;
    appearance: none;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    border: 0;
    background-color: transparent;
}
.searchField:focus{
    outline: none;
}

.Column{
    width: 12%;
}
.LargeColumn{
    width: 18%;
}
.Customers{
    width: 100%; 
    display: flex;
    border-radius: 0.25rem;
    color:#42526E;
    background-color: #e6e8ec;
    padding:7px;
    cursor:pointer;
    font-weight:600;
}
.Box{
    border-radius: 8px;
    box-shadow:0 0 0.3152rem rgba(0, 0, 0, 0.181), 0 0 0.3152rem rgba(0, 0, 0, 0.181);  
}

.MobileResponsiveBox {
    border-radius: 6px;
    box-shadow: 0 0 4px 2px hsla(0,0%,50%,.3);
    margin: 10px 0;
    padding: 15px 10px;
    font-size: 14px;
    border-left: 2px solid #243750;
}

.containBoxes{
    width: 66%;
}
.container{
    padding-left: 3%;
}

.asideContainer{
    width: 28%;
}

.completed{
    width: 95%;
    margin: 20px auto 40px;
    padding: 8px;
    background-color: #F1F8F5;
    border: 1px solid #9BCCB8;
    border-radius: 8px;
}

.DiscountedUnitPrice {
    display: flex;
	color: #0d6efd;
    padding: 0px 5px;
}

.arrowBack{
    width: 95%;
    margin: 10px auto 30px;
    padding-right: 16px;
}

.orderStatus{
    display: flex;
    margin: 0px 0px 0px 10px;
    font-size: 13px;
    background-color: #FFEA8A;
    padding: 0px 10px;
    border-radius: 10px;
    height: 21px;
}

.orderStatusRound{
    width: 10px;
    height: 10px;
    border: 2px solid #D08D45;
    border-radius: 50%;
    margin: auto 6px auto 0px;
}

.paymentStatus{
    display: flex;
    margin: 0px 0px 0px 10px;
    font-size: 13px;
    background-color: #FFD79D;
    padding: 0px 10px;
    border-radius: 10px;
    height: 21px;
}

.paymentStatusRound{
    width: 10px;
    height: 10px;
    border: 2px solid #B98900;
    border-radius: 50%;
    margin: auto 6px auto 0px;
}

.paymentStatusStyles{
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: #FFD79D;
}

.DottedBorderRadius{
    width: 17px;
    height: 17px;
    border: 3px dotted #B98900;
    border-radius: 50%;
    margin: 4px auto;
    background: white;
}

.discountPercent {
    width: 13%;
}

.Qty{
    width: 10%;
    margin-left: 10px;
}

.Total{
    width: 12%;
    padding-left: 12px;
}

.ProductHeader{
    display: flex;
    border-bottom: 1px solid #dbdde0;
    padding: 15px 5px 15px 15px;
}

.ProductContainer{
    gap: 0.5rem;
    width: 55%;
    display: flex;
}

.ProductImage{
    width: 40px;
    height: 40px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.ResponsiveDiscount{
    width: 30%;
    display: flex;
}

.CancelResponsive {
    padding: 20px 0px 20px 20px;
    margin: 0px 0px 15px 0px; 
}

.ResponsiveBox {
    display: none;
    /* margin-top: 5px; */
}

.PaymentBox {
    padding: 20px;
    font-size: 14px;
}

.VariantContainer {
    border-bottom: 1px solid #ccc;
    display: flex;
    /* justify-content: space-between; */
    padding: 20px;
    font-size: 14px;
}

.showResponsive{
    display: none;
}

.orderName{
    width: 10%;
    margin: auto 0px;
}
.ResponsiveCreatedAt{
    display: none;
}

.ResponsiveCustomerName{
    display: none;
}

.ResponsivePaymentStatus{
    display: none;
}

.ResponsiveOrderStatus{
    display: none;
}

.ResponsiveCancelledAt{
    display: none;
}

.ResponsiveOrderType{
    display: none;
}

.PaginateBtn {
    border: 1px solid rgb(229, 231, 235);
    border-radius: 0.5rem;
    overflow: hidden;
    justify-content: center;
    align-items: stretch;
    display: flex;
    width: fit-content;
    margin: auto;
    /* height: 42px; */
    flex-wrap: wrap;
}

.PaginateBtn button:hover {
    background-color: rgb(200, 200, 200);
}

.PaginateBtn button {
    background: none;
    font-family: Poppins,sans-serif;
    color:black;
    border:0;
}

.ordersHeader {
    display: flex;
    font-weight: 600;
    font-size: 15px;
    border-bottom: 2px solid black;
    padding-bottom: 5px;
    background-color: #243750;
    color: #fff;
    padding: 8px 10px;
    margin-top: 10px,
}

.GstInvoiceBox {
    display: none;
}

.OrderName{
    width: 10%;
}

.OrderNameInvoice{
    display: none;
}

.CreatedAt {
    width: 15%;
}

.Customer{
    width: 18%;
}

.Payment {
    width: 12%;
}

.searchContainer{
    display: flex;
    justify-content: space-between;
}
.SearchDraftOrder{
    width: 72%;
}

.searchField{
    width: 98%;
    padding: 0.375rem 0.75rem 0.375rem 4px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-clip: padding-box;
    appearance: none;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    border: 0;
    background-color: transparent;
}
.searchField:focus{
    outline: none;
}

.OrderStatus{
    width: 12%;
}

.CancelledAt{
    width: 15%;
}

.OrderType{
    width: 10%;
}
.SearchOrder{
    width: 100%;
}

.CreatedByFilter{
    position: relative;
    width: 28%;
}
.Spinner {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.totalBoxRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.TaxRatesPrint {
    display: none;
}

.customerName {
    padding: 10px 15px;
	border-bottom:  1px solid rgb(219, 221, 224);
}

@media screen and (max-width: 1044px){
    .completed{
        width: 100%;
    }
    .arrowBack{
        width: 100%;
    }
    .containBoxes{
        width: 100%;
    }
    .container{
        padding-left: 0%;
    }
    .asideContainer{
        width: 100%;
    }
}

@media screen and (max-width:900px) {
    .ResponsiveCreatedAt{
        display: block;
    }
    .CreatedAt {
        display: none;
    }
    .orderName {
        width: 30%;
    }
    .OrderName {
        width: 30%;
    }
    .Customer {
        display: none;
    }
    .ResponsiveCustomerName{
        display: block;
    }
    .Payment {
        width: 18%;
    }
    .OrderStatus {
        width: 18%;
    }
    .CancelledAt {
        width: 25%;
    }
    .asideContainer{
        width: 100%;
    }
}

.headerButtons {
    display: flex;
    gap: 10px;
    margin-top:-25px;
    width: 99%;
    justify-content: end;
    margin-bottom: 15px;
}

@media screen and (max-width:750px) {
    .SearchDraftOrder{
        width: 65%;
    }
    
    .CreatedByFilter{
        position: relative;
        width: 35%;
    }
    .headerButtons {
        margin-bottom: 32px;
        width: 100%;
    }
}

@media screen and (max-width:725px) {
    .orderName{
        width: 39%;
    }
    .OrderName {
        width: 40%;
    }
    .Payment {
        display: none;
    }
    .ResponsivePaymentStatus {
        margin-top: 5px;
        display: flex;
    }
    .OrderStatus {
        width: 20%;
    }
    .OrderType {
        width: 12%;
    }
    .searchContainer{
        margin-top: 30px;
    }
    .asideContainer{
        width: 100%;
    }
}

@media screen and (max-width:600px) {
    .hideResponsive{
        display: none;
    }
    .ProductHeader{
        padding: 13px;
    }
    
    .ProductContainer{
        width: 100%;
    }
    .ProductDetail{
        width: 89%;
    }
    .CancelResponsive {
        font-size: 14px;
    }
    .discountPercent {
        display: none;
    }
    .Qty{
        display: none;
    }
    .Total{
        display: none;
    }
    .ResponsiveBox {
        display: block;
    }
    .ActualTotal {
        display: none;
    }
    .OrderName{
        width: 60%;
    }
    .orderName {
        width: 59%;
    }
    .OrderStatus{
        display: none;
    }
    .ResponsiveOrderStatus {
        display: flex;
        margin-top: 5px;
    }
    .OrderType{
        width: 15%;
    }
    .asideContainer{
        width: 100%;
    }
    .SearchDraftOrder{
        width: 60%;
    }
    
    .CreatedByFilter{
        position: relative;
        width: 40%;
    }
}

@media screen and (max-width:535px) {
    .OrderName {
        width: 73%;
    }
    .orderName {
        width: 70%;
    }
    .CancelledAt{
        width: 30%;
    }
    .OrderType {
        display: none;
    }
    .ResponsiveOrderType {
        margin-top: 5px;
        display: flex;
    }
    .SearchOrder{
        width: 100%;
    }
    
    .CreatedByFilter {
        margin-top: -2rem;
        width: 100%;
        /* margin-bottom: 2rem; */
    }
    .asideContainer{
        width: 100%;
    }
    .SearchDraftOrder{
        width: 100%;
    }
    
    .CreatedByFilter{
        position: relative;
        margin-top: 10px;
        width: 98%;
    }
}

.OrderDetailArrow {
    justify-content: center;
}

.searchBox {
    display: flex;
    width: 98%;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 3px auto;
}

.printInvoice {
    border: 1px solid black;
    background-color: transparent;
    border-radius: 4px;
    padding: 5px 8px;
}

.invoiceTypes {
    display: flex;
    justify-content: end;
    margin: 0px 40px 15px 0px;
}

.returnBox {
    padding: 20px 0px 20px 20px;
    margin: 0px 0px 15px 0px;
}

.subtotal {
    width: 10%;
    padding-left:5px;
    margin-bottom:0px;
}

.appliedDiscount {
    padding: 5px 0px;
    width: 64%;
    display: flex;
}

.shippingAmount {
    display: flex;
    width: 25%;
    justify-content: flex-end;
}

.widthColor {
    width: fit-content;
    color: black;
}

.showTaxRates {
    color: #0d6efd;
    cursor: pointer;
    width: 40%;
}

.customerDetail {
    font-size: 14px;
    color: rgb(13, 110, 253);
    margin-top:10px;
}

.contactInformationBox {
    padding: 10px 15px;
	border-bottom: 1px solid rgb(219, 221, 224);
}

.fontWeightSize {
    font-weight: 600;
    font-size: 13px;
}

.metafieldValue {
    margin-top: 5px;
    font-size: 15px;
    color: rgb(13, 110, 253);
}

.secondMetafieldValue {
    color: rgb(109, 113, 117);
    margin-top: 10px;
    font-size: 15px;
}

.sellerBillingAddress {
    margin-bottom: 0.25rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.GstBox {
    padding: 10px 15px;
    margin-bottom: 15px;
}

.GstNumber {
    font-weight: 600;
	font-size: 14px;
}

.Tags {
    background-color: #E4E5E7; 
    width: fit-content;
    font-size: 14px;
    margin: 10px 10px 0px 0px;
}

.MultiOrders {
    cursor: pointer;
    display: flex;
    border-bottom: 1px solid lightgray;
    font-size: 13px;
    padding: 10px  10px;
}

.MultiOrdersName {
    font-size: 14px;
	margin: auto 0px;
}

.MultiOrdersStatus {
    margin-right:5px; 
    font-weight: 600;
    color: #72767d;
}

.MultiOrdersBorderWidth {
    border-radius: 1rem;
	width: fit-content;
}

.lineItemMrp {
    color: rgb(109, 113, 117);
    margin-right: 9px;
    text-decoration: line-through;
}

.lineItemDiscountTitle {
    width: fit-content;
    background-color: lightgreen;
    padding: 0px 7px;
    border-radius: 11px;
    font-size: 13px;
    margin-left: 5px;
}

@media screen and (max-width:500px) {
    .arrowBack{
        flex-wrap: wrap;
    }
    .OrderDetailArrow{
        justify-content: flex-start !important;
        width: 100%;
    }
    .CancelOrderButton{
        margin-left: 50px;
        font-size: 13px;
    }
}
@media screen and (max-width:425px) {
    .PaymentBox {
        font-size: 13px;
    }

    .VariantContainer{
        font-size: 13px;
        padding: 20px 10px;
    }
    .OrderName {
        width: 100%;
    }
    .orderName {
        width: 100%;
    }
    .ResponsiveFontSize{
        font-size: 14px;
    }
    .CancelledAt {
        display: none;
    }
    .ResponsiveCancelledAt {
        margin-top: 5px;
        display: flex;
    }
}