.modalContainer {
  max-width: 100vw;
  max-height: 100vh;
  display: flex;
  justify-content: center;
  overflow: scroll;
}

.container {
  background-color: #ffffff;
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding: 1.25rem;
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: fit-content;
}
.childContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1rem;
}

.selectContainer {
  flex: 1;
  justify-content: flex-start;
  margin-bottom: 1rem;
}

.options {
  font-size: 0.875rem;
}
.btnContainer {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.swiperParent {
  padding: 1rem 1rem;
  width: 100%;
  align-self: center;
}
.btn {
  background-color: #f56565;
  border-radius: 0.25rem;
  color: white;
  font-size: 1rem;
  font-weight: bold;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  width: 100%;
}

.btn:hover {
  background-color: #c53030;
}

.sliderImg {
  height: auto;
  width: 100%;
}
.sliderTextContainer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  font-size: 1.125rem;
  margin-bottom: 1rem;
}
.storeHeader {
  color: #153750;
  font-size: 2.25rem;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.storeHeaderiPhoneX {
  font-size: 1rem;
  color: #153750;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.storeHeaderLine1 {
  width: 16rem;
  height: 0.25rem;
  background-color: #f46f2c;
  margin: 2px auto;
}
.storeHeaderLine1iPhoneX {
  width: 8rem;
  height: 2px;
  margin: 1px auto;
  background-color: #f46f2c;
}
.storeHeaderLine2 {
  width: 15rem;
  height: 2px;
  background-color: #f46f2c;
}
.storeHeaderLine2iPhoneX {
  width: 7rem;
  height: 1px;
  background-color: #f46f2c;
}

@media (min-width: 640px) {
  .btn {
    width: 50%;
  }
  .selectContainer {
    margin-bottom: 0;
  }
  .childContainer {
    flex-direction: row;
  }
}

@media (min-width: 768px) {
  .btn {
    font-size: 1.125rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .sliderTextContainer {
    margin-bottom: 0.5rem;
    font-size: 0.875rem;
  }
  .options {
    font-size: 1rem;
  }
}
