@media (max-width: 600px) {
  .C {
    flex-direction: column-reverse !important;
  }

  .CC {
    width: 100%;
  }
}

.OrderTextSearch {
  display: flex;
  align-items: center;
  gap: 1rem;
  border: 1px solid #ced4da !important;
  border-radius: 0.25rem;
  padding: 0.3rem 0.75rem;

  div {
    flex-grow: 1;
  }

  input {
    outline: none;
    border: none;
    padding: 0px;
    width: 100%;

    &:focus {
      outline: none;
    }
  }
}
