.TableHead {
  width: 100%;
  background-color: #243750;
  padding: 10px 0px;
  font-size: 1rem;
  font-weight: 500;
  color: #fff;
  text-transform: uppercase;
}

.TableBody {
  font-size: 0.9rem;
}

.UsersHead {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}

.Table > :not(:first-child) {
  border-top: 0;
}

@media only screen and (max-width: 768px) {
  .TableHead {
    padding: 0px 0px;
    font-size: 0.7rem;
  }
  .TableBody {
    font-size: 0.75rem;
  }
  .UsersHead {
    flex-direction: column;
  }
  .UsersHead > button {
    display: none;
  }
  .MobileView {
    display: block;
  }
  .Table {
    display: none;
  }
}
