.custom-accordion{
    border: 2px solid grey;
    padding: 10px;
    border-radius: 1rem;
    width: 50vw;
}

.custom-accordion-item{
    border-radius: 1rem;
    background-color: #ffe1ce;
    padding: 10px;
}

.custom-accordion > div:not(:last-child) {
    margin-bottom: 10px;
}

.custom-accordion-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
}

.custom-accordion-button{
    width: 30px;
    height: 30px;
    background-color: #ff6200;
    text-align: center;
    cursor: pointer;
    border-radius: 20px;
}

.custom-title-content{
    padding-left: 10px;
}

.custom-accordion-content{
    padding: 10px;
    border-top: 2px solid grey;
}