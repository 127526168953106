.mainContainer{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.containBoxes{
    width: 68%;
}

.container{
    margin: 0 auto;
}

.Box{
    width: 100%;
    border-radius: 8px;
    margin-bottom: 1.25rem;
    box-shadow:0 0 0.3152rem rgba(0, 0, 0, 0.181), 0 0 0.3152rem rgba(0, 0, 0, 0.181);  
}

.containerBox{
    padding: 1.1rem;
}
.minPadding{
    padding: 0.5rem 1.1rem;
}

.searchContainer{
    display: flex;
    justify-content: space-between;
}

.UpdateSalesDialog {
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1300;
    position: fixed;
    top: 0%;
    width: 100%;
    left: 0%;
    bottom: 0%;
}

.searchField{
    width: 98%;
    padding: 0.375rem 0.75rem 0.375rem 4px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-clip: padding-box;
    appearance: none;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    border: 0;
    background-color: transparent;
}

.searchField:focus{
    outline: none;
}

.submitButton{
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375rem 1.1rem;
    font-size: 1rem;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, 
    background-color 0.15s ease-in-out, 
    border-color 0.15s ease-in-out, 
    box-shadow 0.15s ease-in-out;
    border: 1px solid #ced4da;
}

.fontSize{
    font-size: 14px;
}

.commonTextWidth p{
    width: 30%;
}

.commonTextStyle{
    text-align: end;
}

.containerFooter{
    border-top: 1px solid #ced4da; 
    font-size:14px; 
    padding: 1rem 1.1rem;
}

.asideContainer{
    width: 30%;
}

.Background{
    width: 100%;
}

.Table{
    border-collapse: collapse;
}

.Thead{
    border-bottom: 1px solid;
    border-bottom-color: #DBDDE0 !important;
    display: flex;
}

.containProduct{
    display: flex;
    padding: 15px 10px;
    border-bottom: 1px solid #DBDDE0 !important;
}

.productDetail{
    width: 52%;
    padding: 10px 0px 10px 5px;
    display: flex;
}

.sendPIButton {
    border: 1px solid black;
    padding: 7px 16px;
    background-color: transparent;
    border-radius: 5px;
    font-weight: 600;
    letter-spacing: 1px;
}

.editOrderButton {
    border: 0px;
    font-size: 14px;
    background-color: #009688;
    color: #fff;
    padding: 7px 8px;
    border-radius: 4px;
    cursor: pointer;
    width: 116px;
    font-weight: 600;
}

.deleteOrderButton{
    border: 0px;
    font-size: 14px;
    background-color: #009688;
    color: #fff;
    padding: 7px 8px;
    border-radius: 4px;
    cursor:pointer;
    width: 116px;
    font-weight: 600;
    margin-right: 10px;
}

.completeOrderButton{
    border: 0px;
    padding: 7px 16px;
    background-color: #6c943eeb;
    color: #fff;
    border-radius: 5px;
    font-weight: 600;
    letter-spacing: 1px;
}
.waitingOrderButton{
    border: 0px;
    padding: 7px 16px;
    background-color: #20036aeb;
    color: white;
    border-radius: 5px;
    font-weight: 600;
    letter-spacing: 1px;
    opacity: 0.7;
}

@media screen and (max-width: 1115px){
    .rateFontSize{
        font-size: 13px;
    }
}

.hideHeader{
    display:none;
}

.draftOrderName{
    display: none;
}

.ProductHeading{
    width: 55%;
}

.TaxRatesPrint{
    display: none;
}

.customerVertical{
    display: none;
}

.productImage{
    width: 40px;
    height: 40px;
    border: 1px solid #ced4da;
    border-radius: 4px;
}

@media screen and (max-width: 1044px){
    .containBoxes{
        width: 100%;
    }
    .asideContainer{
        width: 100%;
    }
}


.Input::-webkit-inner-spin-button, 
.Input::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    /* appearance: none; */
    margin: 0; 

}
.Input:focus{
    outline: none;
}
.Qty{
    width: 18%;
    display: flex;
    border: 1px solid #DBDDE0;
    height: 44px;
    border-radius: 4px;
}

.IncBtn{
    background: #E4E5E7;
    margin: 2px;
    padding: 0px 4px;
    border-radius: 2px;
    height: 18px;
    cursor: pointer;
}
.IncQtyBtn{
    width: 12px;
	vertical-align : text-top !important; 
}
.DecBtn{
    background: #E4E5E7;
    margin: 2px;
    padding: 0px 4px; 
    border-radius: 2px;
    height: 18px;
    padding-top: 3px;
    cursor: pointer;
}
.DecQtyBtn{
    width: 12px;
    transform: rotate(180deg);
    vertical-align: top !important;
}

select{
    width: 100%;
    padding: 5px 5px;
    border-color: #ced4da;
    border-radius: 4px;
    margin-top: 5px;
}

select:focus{
    border-color: #0d6efd;
    outline: none;
}

.MRPLESS{
    font-size: 13px;
    color: #fff;
    display: none;
}

.MRPCONTENT{
    width: fit-content;
    background-color: red;
    padding: 0px 5px;
    border-radius: 5px;
    margin-bottom: 5px;
}

.Span{
    border: 0px !important;
    padding: 5px;
    outline: none;
    border: ridge 2px;
    border-radius: 4px;
    min-height: 3em;
    overflow: auto;

}

.Span:focus{
    outline: 2px solid #458fff
}

.VariantDetail{
    margin-left: 15px;
}

.ShowResponsive{
    display: none;
}
.ShippingAddressBox{
    border-right: 1px solid rgb(219, 221, 224);
    width: 33%;
}
.customerBox{
    border-right: 1px solid rgb(219, 221, 224);
    width:33%;
}

.contactTitle{
    margin: auto 0px;
    font-size: 12px;
    font-weight: 600;
}

.AddressTitle{
    margin: auto 0px;
    font-size: 12px;
    font-weight: 600;
}



.MetafieldBox{
    padding: 0px 1.1rem;
	border-top: 1px solid #DBDDE0;
}

.GstContent{
    margin: 5px 0px;
    font-weight: 600;
    font-size: 12px;
}

.GstNumber{
    color:  #2c6ecb;
    font-size: 13px;
    padding-bottom: 3px;
}

.hideDiscountTitle {
    font-size: 12px;
    background-color: #93d62dcc;
    border-radius: 11px;
    padding: 0px 5px;
    width: fit-content;
    margin-left: 5px;
    margin-top:2px,
}

.hideDiscountNewTitle {
    font-size: 12px;
    background-color: #93d62dcc;
    border-radius: 4px 4px 4px 4px;
    padding: 0px 4px;
    width: fit-content;
    margin: auto;
}

.asideCustomer {
    display: flex;
	justify-content: space-between;
    margin-bottom: 1.5rem;
}

.asideHeaders {
    margin: auto 0px;
    font-size: 12px;
    font-weight: 600;
}

.asideMetafields {
    padding: 10px 1.1rem;
    margin: 0px 0px 20px 0px;
}

.tagsBox {
    display: flex;
    flex-wrap: wrap;
    font-size: 13px;
}

.tags {
    background-color: #E4E5E7;
    padding: 2px 10px;
    margin: 0px 3px 3px;
    border-radius: 4px;
}

.remarkBox {
    font-size: 15px;
    color: gray;
    margin-top: 10px;
}

.TaxRatePrintHeader {
    padding: 10px 20px;
    border-bottom: 1px solid lightgray;
}

.TaxRateBox {
    display: flex;
    justify-content: space-between;
    padding: 5px;
    font-weight: 600;
}

.Quantity {
    width: 14%;
    text-align: left;
    margin-top: 12px;
}

.Quantity2 {
    width: 15%;
    text-align: center;
    margin-top: 12px;
}

.BinSelect {
    width: 17%;
    font-size: 14px;
    text-align: center;
    padding: 0px 6px;
    /* margin-top: 12px; */
}

.DiscountedUnitPrice {
    display: flex;
	color: #0d6efd;
}

.PercentBox {
    width: 16%;
    padding: 10px 0px;
    font-size: 14px;
}

.Text {
    color: #6D7175;
	margin-right: 5px;
}

.rateExclGst {
    display: flex;
	margin-right: 8px;
}

.newRateExclGst {
    color: rgb(109, 113, 117);
	margin-right: 4px;
}

.compareAtPrice {
    display: flex;
    color: #0d6efd;
    text-decoration: line-through;
}

.newCompareAtPrice {
    color: rgb(109, 113, 117);
    text-decoration: line-through;
    margin-left: 3px;
}

@media screen and (max-width: 650px) {
    .hideResponsive {
        display: none;
    }
    .productDetail{
        width: 100%;
    }
    .percent{
        display: none;
    }
    .VariantDetail{
        margin-left: 10px;
        width: 100%;
    }
    .hideResponsive{
        width: 15%;
    }
    .ShowResponsive{
        font-size: 13px;
        display: block;
    }
}

@media screen and (max-width:500px) {
    .hideResponsive{
        width: 18%;
    }
    .containerFooter{
        padding: 1rem 0.4rem;
        font-size: 13px;
    }
    .completeOrderButton{
        font-size: 13px;
        padding: 7px 5px;
    }
    .deleteOrderButton{
        width: 90px;
        font-size: 13px;
        padding: 7px 5px;
    }
    .editOrderButton{
        width: 90px;
        font-size: 13px;
        padding: 7px 5px;
    }
    .productImage{
        width: 35px;
        height: 35px;
    }
}


@media screen and (max-width:450px) {
    .Header{
        width: 100% !important;
    }
    .hideResponsive{
        width: 21%;
    }
    .ShowResponsive{
        font-size: 13px;
    }
}

@media screen and (max-width:425px) {
    .Header{
        width: 102% !important;
    }
    .logo{
        width: 100px;
    }
}


@media screen and (max-width:400px) {
    .Header{
        width: 100% !important;
    }
    .hideResponsive{
        width: 25%;
    }
    .sendPIButton{
        width: 78px;
        font-size: 14px;
        padding: 7px;
    }
    .draftOrderDetail{
        font-size: 14px;
    }
}

@media screen and (max-width:370px) {
    .logo{
        width: 80px;
    }
}

