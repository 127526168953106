.Container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 20px;
  row-gap: 20px;
  margin-top: 2%;
}

@media (max-width: 1024px) {
  .Container {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 768px) {
  .Container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 576px) {
  .Container {
    grid-template-columns: repeat(1, 1fr);
  }
}
.Item {
  /* border: 1px solid grey; */
  box-shadow: 0px 0px 11px 0px #e6e6e6;
  border-radius: 10px;
  height: 100px;
  cursor: pointer;
  padding: 5px;
  transition: all 0.3s ease-in-out;
}
.Item h4 {
  color: rgb(100, 100, 100);
}
.Item:hover {
  box-shadow: 0px 0px 20px 5px #e6e6e6;
  transition: all 0.3s ease-in-out;
}

.BtnImage {
  width: 35%;
}

.searchContainer {
  width: 100%;
  display: flex;
  border: 1px solid #ced4da !important;
  border-radius: 0.25rem;
}

.searchIcon {
  font-size: 15px;
  padding: 11px 8px 3px;
  color: rgb(129, 138, 155);
}

.searchField {
  width: 98%;
  padding: 0.375rem 0.75rem 0.375rem 4px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-clip: padding-box;
  appearance: none;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  border: 0;
  background-color: transparent;
}
.searchField:focus {
  outline: none;
}

.ItemsContainer {
  width: 70%;
  box-shadow: 0.2px 0.4px 6px 5px rgb(230, 230, 230);
  border: 1px solid lightgrey;
  margin: 15px auto;
  border-radius: 10px;
}
.Items {
  display: "flex";
  margin: 15px 15px;
  gap: 8px;
}
