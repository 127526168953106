.collapser {
    background: white;
    height: 25px;
    width: 25px;
    position: fixed;
    left: 226px;
    top: 17.6vh;
    z-index: 1703 !important;
    border-radius: 50%;
    box-shadow: 1px 1px 11px -4px grey;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

.collapser-collapsed {
    left: 59px;
    transition: all 0.3s ease-in-out;
}