.modalContainer {
  max-width: 100vw;
  max-height: 100vh;
  display: flex;
  justify-content: center;
  overflow: scroll;
}

.container {
  background-color: #ffffff;
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding: 1.25rem;
  display: flex;
  flex-direction: column;
  width: fit-content;
  height: fit-content;
}

.childContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1rem;
}

.selectContainer {
  flex: 1;
  justify-content: flex-start;
  margin-bottom: 1rem;
}

.options {
  font-size: 0.875rem;
}

.btnContainer {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.btn {
  background-color: #f56565;
  border-radius: 0.25rem;
  color: white;
  font-size: 1rem;
  font-weight: bold;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  width: 100%;
}

.btn:hover {
  background-color: #c53030;
}

.videoWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
}
.videoWrapperiPhoneX {
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
}

.videoContainer {
  width: 80%;
  height: 24rem;
}
.videoContaineriPhoneX {
  width: auto;
  height: 24rem;
}
.textContainer {
  padding: 1.5rem 0;
  width: 50%;
  text-align: center;
}
.textContaineriPhoneX {
  padding: 1.5rem 0;
  width: auto;
  text-align: center;
}

.logoImage {
  width: auto;
  height: 5rem;
  margin: auto;
  margin-bottom: 1rem;
}
.logoImageiPhoneX {
  width: auto;
  margin: auto;
  margin-bottom: 1rem;
  height: 3rem;
}
.description {
  font-size: 1.25rem;
  color: #14364f;
  font-weight: 500;
}
.descriptioniPhoneX {
  font-size: 0.875rem;
  color: #14364f;
  font-weight: 500;
}

.ctaButton {
  background-color: #f46f2c;
  color: white;
  border: 2px solid black;
  padding: 0.25rem 1rem;
  margin-top: 1rem;
}
.ctaButtoniPhoneX {
  background-color: #f46f2c;
  color: white;
  border: 2px solid black;
  padding: 0.25rem 1rem;
  margin-top: 1rem;
  font-size: 0.875rem;
}
.customerContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2rem;
}
.customerContaineriPhoneX {
  display: flex;
  align-items: center;
  padding: 2rem;
  flex-direction: column-reverse;
}

@media (min-width: 640px) {
  .childContainer {
    flex-direction: row;
  }

  .selectContainer {
    margin-bottom: 0;
  }

  .options {
    font-size: 1rem;
  }

  .btn {
    width: 50%;
  }
}
@media (max-width: 640px) {
  .videoContainer {
    max-height: 20rem;
  }
}
@media (max-width: 768px) {
  .customerContainer {
    flex-direction: column-reverse;
  }
  .videoWrapper {
    width: auto;
  }
  .videoContainer {
    width: auto;
  }
  .description {
    font-size: 0.875rem;
  }
  .ctaButton {
    font-size: 0.875rem;
  }
  .textContainer {
    padding: 0.5rem 0;
    width: auto;
  }
  .logoImage {
    height: 3rem;
  }
}
