.modal {
    position: fixed;
    top: 4%;
    right: 25%;
    bottom: 10%;
    left: 25%;
    box-shadow: 0 10px 30px 0 rgba(127, 127, 127, 0.3);
    background: #fff;
    border-radius: 10px;
    z-index: 1000;
}
.Box{
    border-bottom: 1px solid #ced4da;
}

.BoxContent{
    display: flex;
    justify-content: space-between;
    font-size: 20px;
    padding: 20px 25px;
}
.Border{
    box-shadow: 0 5px 5px -5px #000000;
}
.Footer{
    display: flex;
    justify-content: space-between;
    padding: 20px;
    padding: 20px;
    position: fixed;
    bottom: 7%;
    width: 50%;
    border-top: 1px solid #d2d5d8;
    box-shadow: 0 -5px 5px -5px #000000;;
}
.Button button{
    background: #fafbfb;
    border: 1px solid #d2d5d8;
    padding: 5px 15px;
    border-radius: 4px;
    color: #8c9196;
}

.TextAlignRight{
    display: flex;
    justify-content: space-between;
}
.Add div{
    margin: 0px 5px; 
}

.Search{
    display: flex;
    border:1px solid #ced4da;
    padding:0px 10px; 
    border-radius:4px;
}
.Boxes{
    padding: 1.1rem;
    align-items: center;
    border-bottom: 1px solid #c9cccf;
    display: flex;
}
.BoxContainer{
    height: 61.5%;
    overflow-y: auto;
}
input[type=checkbox]{
    width: 18px;
    height: 18px;
    margin-top: 5px;
}

.inputFields {
    width: 100%;
    margin-top: 5px;
    padding: 5px;
    border: 1px solid #C2C5C9;
}

.Boxes:hover{
    background-color: #F6F6F7;
}
.Child{
    padding: 0.8rem;
    align-items: center;
    border-bottom: 1px solid #c9cccf;
    display: flex;
    padding-left: 3rem;
    justify-content: space-between;
}
.Child:hover{
    background-color: #F6F6F7;
}

.SearchTag:focus{
    outline: none;
}

.CancelButton {
    background-color:#C1C1C1;
    border: 0px;
    border-radius: 4px;
    color: #fff;
    font-size: 20px;
    padding: 6px;
    width: 120px;
}

.confirmModal {
    position: fixed;
    top: 5%;
    right: 35%;
    bottom: 8%;
    left: 35%;
    box-shadow: 0 10px 10px 0 rgba(127, 127, 127, 0.3);
    background: #fff;
    border-radius: 10px;
    z-index: 1000;
}

.Button{
    display: flex;
    justify-content: space-between;
    padding: 15px;
    margin: 0px auto;
    width: 50%;
}

.DefaultBtn {
    border:  1px solid gainsboro;
    background-color: transparent;
    border-radius: 4px;
    padding: 5px 10px;
    font-weight: 600;
    font-size: 15px;
}

.DefaultAddressBox {
    margin-top: 10px;
    border-bottom: 1px solid #ced4da;
    padding: 20px;
}

.ShippingAddressPopup {
    padding: 5px 20px;
    overflow-y: auto;
    height: 449px;
    font-size: 15px;
}

.TagsSearchBox {
    width: 72%;
    display: flex;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    padding: 2px 4px 4px 4px;
}

.TagsSearchIcon {
    font-size: 15px;
    padding: 11px 8px 3px;
    color: rgb(129, 138, 155);
}

.TagsFrequentlyUsedIcon {
    font-size: 15px;
    padding: 11px 8px 3px;
    margin-top: 5px;
}

.TagsFrequentlyUsedBtn {
    border:0px;
    padding: 0px;
    background-color: transparent;
    font-weight: 600;
    font-size: 14px;
}

.TagsFooterBox {
    display: flex !important;
    justify-content: flex-end !important;
    border-top: 1px solid #d2d5d8 !important;
    padding: 15px 20px !important;
    width: 100%;
}

.TagsFooterBtn {
    background-color: #006E52 !important;
    color: #fff !important;
    font-size: 15px !important;
    padding: 6px 15px !important;
}
.AddressBox {
    border-bottom:  1px solid #ced4da;
    padding: 20px;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
}

.RequiredField {
    color: #FF6200;
    margin-left: 2px;
}

.ShippingAddressField {
    width: 100%;
    margin-top: 5px;
    padding: 5px;
    border: 1px solid #C2C5C9;
    border-radius: 4px;
}

.ShippingRow {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
}

.PhoneErrorMsg {
    background-color: red;
    color: #fff;
    border-radius: 4px;
    padding: 0px 5px;
    margin-top: 5px;
    font-size: 14px;
    display: none;
}

.IsValidGst {
    font-size: 14px;
    background-color: red;
    color: white;
    padding: 0px 5px;
    margin-top: 6px;
    border-radius: 4px;
    width: fit-content;
    display: none;
}

.PhoneContentBox{
    display: flex;
    border:  1px solid rgb(194, 197, 201);
    border-radius: 4px;
    margin-top: 5px;
}

.PhoneCountry{
    padding: 5px;
    border-right: 1px solid rgb(194, 197, 201);
}

.CircleXMarkBox {
    text-align: center;
    margin: 30px 0px;
}

.CircleXMark {
    font-size: 75px;
    color: #F37171;
    cursor: pointer;
}

.AreYouSure {
    color: #808080;
    text-align: center;
    font-size: 30px;
}

.DoYouReallyWantTo {
    color: #808080;
    display: flex;
    justify-content: center;
    padding: 10px 0px;
}

.DialogBtnBox {
    display: flex;
    justify-content: flex-end;
    bottom: 16%;
    position: fixed;
    box-shadow: none;
    padding: 20px;
    width: 50%;
    border-top: 0px;
}

@media only screen and (max-width: 768px) {
    .modal{
        top: 18%;
    right: 25%;
    bottom: 30%;
    left: 10%;
        width: 80%;
    }

    .Button{
        width: 80%;
    }


}