.DashboardContainer {
    padding:  5px 3px
}

.DashboardHeader {
    display: flex; 
    justify-content: space-between; 
    flex-direction: row;
    margin: auto 0px;
}

.DashboardTabDropDown {
    width : 50%; 
    display: flex; 
    justify-content: end;
    margin-right: 3px;
}
.DashboardTabDropDown > div {
    width: 30%; 
    margin-left: 10px;
}

.DatePickerContainer {
    display:flex; 
    margin : 15px auto; 
    margin-right : 2px;
    justify-content:space-between; 
    width : 31%;
}

.Container {
    width: 100%;
    border-radius: 8px;
    margin-bottom: 1.25rem;
    font-family: Arial, Helvetica, sans-serif;
}

.AnalyticsContainer {
    display: flex;
    width: 100%;
}

.CardContainer {
    width: 60%;
    display: grid;
    grid-template-columns: auto auto;
    gap: 20px;
}
.Card {
    height : 100px;
    border-radius: 10px;
    padding: 15px;
    box-shadow:0 0 0.5152rem rgba(0, 0, 0, 0.281);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.UserActivityCardContainer {
    width: 40%;
    margin-left: 20px;
}

.UserActivityCardContainer > .UserActivityCardClass {
    height : 220px !important;
    max-height: 220px !important;
    /* overflow-y: auto;
    overflow-x: hidden; */
    border-radius: 10px;
    padding: 0px 0px;
    box-shadow:0 0 0.5152rem rgba(0, 0, 0, 0.281);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 20px;
    position: relative;
}

.OrderActivity {
    color: #323232;
}

.OrderActivity:hover {
    color: #ea8f6b;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

.dot {
    position: absolute;
    left: -4px;
    top: 22px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #ea8f6b;
}

.line {
    position: absolute;
    left: 15px;
    top: 37px;
    width: 2px;
    background-color: #f0d3c8;
}

.CardHeading {
    color : rgba(97, 94, 97,1);
    font-size: 1.3rem;
    font-weight: bolder;
}

.CardTitle {
    font-weight: 200;
}

.GraphContainer {
    margin-top: 30px;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
}

.Graph {
    height: 70vh;
    border-radius: 6px;
    width: 99%;
    padding: 15px;
    box-shadow:0 0 0.6152rem rgba(0, 0, 0, 0.281);
}

.GraphHeight {
    height: 63vh;
}

.TableContainer {
    margin-top: 20px;
}

.reportAnalytics {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.reportAnalyticsItem {
    flex: 1;
    margin: 5px;
}

@media screen and (max-width:1100px){
    
}
@media screen and (max-width : 960) {
}
@media only screen and (max-width: 768px) {
    .AnalyticsContainer {
        flex-wrap: wrap;
    }
    .CardContainer {
        grid-template-columns: 1fr 1fr 1fr;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
    }
    .DatePickerContainer {
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
    }
    .DashboardTabDropDown > div {
        width: 40%;
    }
}

@media screen and (max-width:600px){
    .GraphContainer {
        grid-template-columns: 1fr;
    }
    .CardContainer {
        grid-template-columns: 1fr 1fr;
    }
    .DatePickerContainer {
        flex-direction: row;
        justify-content: space-between;
        margin: 10px 0px;
        width: 100%;
        margin-bottom: 20px;
    }
    .Card {
        width: 100%;
    }
    .UserActivityCardContainer {
        width: 100%;
        margin-left: 0px;
        margin-top: 20px;
    }
    .DashboardHeader {
        flex-direction: column;
    }
    .DashboardTabDropDown {
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
    }
    .DashboardTabDropDown > div {
        width: 49%;
        margin: 10px 0px;
    }
    .reportAnalyticsItem {
        min-width: 150px;
    }
}

.root {
    height: 220px;
    max-height: 220px;
    border-radius: 10px;
    padding: 15px;
    box-shadow: 0 0 0.5152rem rgba(0, 0, 0, 0.281);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 20px;
}

.timeline {
    border-left: '2px solid #e0e0e0';
    margin-left: 10px;
    padding-left: 10px;
}

.listItem {
    padding: 10px;
}

.timeText {
    color: grey;
}

.Box {
    border-radius: 6px;
    box-shadow: 0 0 4px 2px hsla(0,0%,50%,.3);
    margin: 10px 0;
    padding: 15px 10px;
    font-size: 14px;
    border-left: 2px solid #243750;
}