.Header{
    /* margin-top: 5px; */
    font-weight: 600;
    margin: 5px 1.5rem;
    font-size: 14px;
}

.Heading{
    font-weight: 700;
    padding: 5px 15px;
    font-size: 16px;
}

.Container{
    box-shadow: 0 10px 30px 0 rgba(127, 127, 127, 0.3);
    background: #fff;
    border-radius: 10px;
}
.searchField{
    width: 98%;
    padding: 0.375rem 0.75rem 0.375rem 4px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-clip: padding-box;
    appearance: none;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    border: 0;
    background-color: transparent;
}
.searchField:focus{
    outline: none;
}

.UpdateSalesDialog {
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1300;
    position: fixed;
    top: 0%;
    width: 100%;
    left: 0%;
    bottom: 0%;
}

.ContainerHeader{
    display: flex;
    justify-content: center;
}
/* .Border:focus{
    border: 2px solid skyblue !important;
} */

.select, .selectVendor{
    /* width: 100%; */
    padding: 7px 5px;
    border-color: #ced4da;
    border-radius: 4px 0px 0px 4px;
    margin-top: 0px !important;
}
.selectVendor{
    width: 190px;
}
.ProductHeader{
    display: flex;
    margin-top: 15px;
    font-weight: 600;
    font-size: 15px;
    padding-bottom: 5px;
    /* color:#8d8f93; */
    border-bottom: 2px solid black;
}

.isActive {
    width: 56px;
    background-color: darkcyan;
    color: white;
    padding: 0px 10px;
    border-radius: 10px;
    margin-left: 6px;
    font-size: 14px;
    text-align: center;
}
.SearchDraftOrder{
    width: 60%;
}

.CreatedByFilter{
    position: relative;
    width: 40%;
}

.Boxes{
    padding: 0.6rem 0px;
    /* align-items: center; */
    
    display: flex;
}

.Box {
    border-radius: 6px;
    box-shadow: 0 0 4px 2px hsla(0,0%,50%,.3);
    margin: 10px 0;
    padding: 5px;
    font-size: 14px;
    border-left: 2px solid #243750;
}

.Boxes:hover{
    background-color: #F6F6F7;
}

.AddProductButton{
    background-color: transparent;
    border: 1px solid black;
    margin-bottom: 40px;
    padding: 0px 16px;
    color: black;
    cursor: none;
    border-radius: 4px;
    font-weight: 600;
    max-height: 45px;
}

.HeaderContainer{
    /* border-bottom: 0.5px solid lightgray; */
    display: flex;
    padding: 15px;
    justify-content: space-between;
}
.AllOrders {
    padding: 2px 10px;
    background-color: #f1f8f5;
    color: #007f5f;
    margin: auto 10px auto 0px;
    border-radius:4px;
}

.ActiveAndDraft {
    padding: 2px 10px;
    margin: auto 10px auto 0px;
}

.ProductContainer {
    /* padding: 8px 20px; */
    margin-top: 10px;
}

.SearchProduct {
    width: 65%;
    display: flex;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
}

.SearchIcon {
    font-size: 15px;
    padding: 11px 8px 3px;
    color: rgb(129, 138, 155);
}

.ProductImages {
    width: 40px;
    height: 40px;
    margin: 0px 10px;
    border: 1px solid #ced4da;
    border-radius: 4px;
}

.checkBox {
    border: 2px solid #8c9196;
    appearance: none;
    -webkit-appearance: none;
    outline: none;
    border-radius: 4px;
}

.checkBox:checked {
    appearance: auto;
    border-radius: 2px solid #8c9196;
    clip-path: circle(65% at 50% 50%);
}

.showErrorMsg {
    width: fit-content;
    background-color: red;
    color: white;
    margin-left: 3px;
    margin-bottom: 10px;
    padding: 5px 20px;
    border-radius: 23px;
    font-size: 14px;
    display: none;
}

.DeleteBtn {
    border: 1px solid black;
    border-radius: 4px;
    padding:5px 10px;
    color: black;
    background-color: transparent;
    font-size: 14px;
    height: 32px;
    margin-right: 10px;
}
.ExportBtn{
    justify-content:space-between;
    display: flex;
    width: 100%;
    align-items: end;
    margin: 10px 0px;
}

.ImportBtn{
    border: 1px solid #243750;
    background-color: #243750;
    padding: 6px 8px;
    border-radius: 6px;
    color: white;
    /* width: 50px; */
}

.ExportAllBtn {
    border: 1px solid #243750;
    background-color: #243750;
    padding: 6px 8px;
    border-radius: 6px;
    color: white;
}

.ExportAllBtn:active {
    box-shadow: 0px 0px 4px 4px lightskyblue;
}

.ProductText{
    width: 15%;
    margin: auto 0px;
    font-size: 14px;
}
.ProductHeadText{
    font-size:14px;
    margin: auto 0px;
    font-weight: 600;
}
.ProductVendorText{
    width: 10%;
}
.Name{
    display: flex;
    width: 61%;
}

.Img{
    width: 40px;
    height: 40px;
    margin: 0px 20px;
    border: 1px solid #ced4da;
    border-radius: 4px
}

.MobContainerHeader{
    display: none;
    justify-content: space-between;
    width: 100%;
}

.SearchBar, .SearchBar input{
    width: 100%;
    height: 35px;
}

.isActiveDiv{
    width:14%;
    margin:auto 0px;
}

.file {
    width: 100%;
    position: relative;
    margin: 0px 5px;
    display: flex;
    justify-content:end ;
    align-items: center;
  }
  
  /* .file > input[type='file'] {
    display: none
  } */
  
.file > label {
    font-size: 1rem;
    font-weight: 300;
    cursor: pointer;
    outline: 0;
    user-select: none;
    border-color: rgb(216, 216, 216) rgb(209, 209, 209) rgb(186, 186, 186);
    border-style: solid;
    border-radius: 4px;
    border-width: 1px;
    background-color: hsl(0, 0%, 100%);
    color: hsl(0, 0%, 29%);
    padding: 5px 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}
  
.file > label:hover {
    border-color: hsl(0, 0%, 21%);
}
  
.file > label:active {
    background-color: hsl(0, 0%, 96%);
}
  
.file > label > i {
    padding-right: 5px;
}
  
.FileContainer{
    display: flex;
    /* padding: 0px 10px; */
    justify-content: space-between;
    /* flex-direction: column; */
}

.UpdateSalesDialog {
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1300;
    position: fixed;
    top: 0%;
    width: 100%;
    left: 0%;
    bottom: 0%;
}
  
.BrandFilter {
    width: 50%;
}
.SortingFilter{
    width: 50%;
}

@media only screen and (max-width: 768px) {
    .file {
        width: 100%;
    }
    .ContainerHeader{
        display: none;
    }
    .AddProductButton{
        /* display: none; */
        width: 100%;
        font-size: 20px;
        padding: 10px 10px;
        margin-bottom: 20px;
        text-align: center;
    }
    .HeaderContainer{
        display: flex;
    }
    .MobContainerHeader{
        display: flex;
    }
    .orderType {
    /* visibility: hidden; */
      display: none;
      /* justify-content: center; */
    }
    .ProductText{
        display: flex;
        width: 100%;
        font-size: 13px;
        /* margin-top: -29px;
        margin-left: 37px;
        text-align: left; */
    }
    .ProductHeadText{
        font-size: 13px;
    }
    .ProductVendorText{
        display: flex;
        font-size: 13px;
        justify-content: left;
        /* text-align: center; */
        width: 100%;
        /* padding: 0px 38px; */
    }
    .ProductHeader{
        display: none;
    }
    .ProductText p{
        margin-bottom: 0px;
        width: 100%;
    }
    .Header{
        display: none;
    }
    .searchField{
        font-size: 13px;
        /* text-align: center; */
    }
    .select, .selectVendor{
        font-size: 13px;
    }
    .selectVendor{
        width: 160px;
    }
    .Boxes{
        flex-direction: column;
        width: 100%;
        padding: 5px;
    }
    
    .Name{
        width: 100%;
        margin-bottom: 5px;
    }
    .Name div img{
        margin: 0px 0px;
        margin-right: 10px;
    }
    .checkBox{
        position: absolute; ;
        /* right: 29px; */
        /* display: none; */
    }
    .isActive{
        align-items: right;
        margin-top: 0px;

        /* margin: 0px 0px; */
        padding: 0px 0px;
    }
    .isActiveDiv{
        width: 100%;
        display: flex;
        font-size: 13px;
        /* justify-content: right;
        align-items: end; */
    }
    .FileContainer{
        display: flex;
        /* justify-content: center; */
        align-items: center;
        justify-content: space-between !important;
        margin-top: 12px !important;
        flex-wrap: wrap;
        /* width: 100%; */
    }
    .ProductDetails {
        width: 100%;
        display: flex;
        font-size: 13px;
    }

    .SearchDraftOrder {
        width:100%;
    }
    .CreatedByFilter {
        width: 100%;
        flex-wrap: wrap;
    }

    .BrandFilter {
        width: 100%;
        margin-top: 5px;
    }
    .SortingFilter {
        width: 100%;
        margin-top: 5px;
    }
  }