.Table thead{
    background-color: #243750 ;
    color: #fff;
    font-size: 16px;
}

.Table > :not(:first-child) {
    border-top: 0;
}

.ReloadBtn{
    display: flex;

}

@media only screen and (max-width: 768px) {

    .ReloadBtn{
        display: none;
    }
}
