.Grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    gap: 2rem;

    .Span {
        grid-row: 2;
        grid-column: 1 / span x 2;
    }
}
.UploadContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}
  
.UploadAnimation {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 10px; 
  }