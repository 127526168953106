.container{
    margin: 0 auto;
    max-width: 66.375rem;
}
.mainContainer{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 14px;
}
.containBoxes{
    width: 66%;
    margin-bottom: 15px;
}
.containerBox{
    padding: 1.1rem;
}
.Box{
    width: 100%;
    border-radius: 8px;
    margin-bottom: 1.25rem;
    padding: 1.1rem;
    box-shadow:0 0 0.3152rem rgba(0, 0, 0, 0.181), 0 0 0.3152rem rgba(0, 0, 0, 0.181);  
}

.Pricing{
    width: 100%;
    border-radius: 8px;
    margin-bottom: 1.25rem;
    box-shadow:0 0 0.3152rem rgba(0, 0, 0, 0.181), 0 0 0.3152rem rgba(0, 0, 0, 0.181); 
}

.searchField{
    width: 98%;
    padding: 0.300rem 0.75rem 0.300rem 4px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-clip: padding-box;
    appearance: none;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    border: 0;
    background-color: transparent;
}
.qty{
    width: 98%;
    padding: 0.300rem 0 0.300rem 4px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-clip: padding-box;
    appearance: none;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    border: 0;
    background-color: transparent; 
}
.qty:focus{
    outline: 2px solid #458fff !important;
}
.searchField:focus{
    outline: 2px solid #458fff !important;
}

.imgInput{
    width: 98%;
    padding: 0.300rem 0.75rem 0.300rem 4px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-clip: padding-box;
    appearance: none;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    border: 0;
    background-color: transparent;
    /* display: none; */
}
.imgInput:hover{
    outline: 2px solid #458fff !important;
}
/* .Label{ 
    height: 120px;
    border: 1px dotted gray;
    width: 100%;
    border-radius: 4px;
} */

/* .Label:hover{
    background-color: #FAFBFB;
} */

.Option{
    padding:1.1rem 2rem;
    display: flex;
}
.DiscountBox{
    display: flex;
    padding-top: 5px;
    font-size: 14px;
}
.Discount{
    background: greenyellow;
    padding: 0px 10px;
    margin-left: 5px;
    border-radius: 4px;
}

.TrashIcon:hover{
    color:#000000 !important;
}
.asideContainer{
    width: 32%;
}
.OptionName {
    position: absolute;
    left: 0%;
    box-shadow: 0 10px 30px 0 rgba(127, 127, 127, 0.3);
    background: #fff;
    border-radius: 10px;
    z-index: 1000;
    font-size: 14px;
    padding: 5px;
    width: 100%;
    margin-top: 4px;
    display: none;
}

.hidePopUp {
    display: none;
}

.OptionValue {
    position: relative;
    left: 0%;
    background: #fff;
    border-radius: 10px;
    z-index: 1000;
    font-size: 14px;
    width: 100%;
    margin-top: 4px;
    /* display: none; */
}

.addOption{
    width: 98%;
    padding: 0.300rem 0.75rem 0.300rem 4px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-clip: padding-box;
    appearance: none;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    border: 0;
    background-color: transparent;
    border: 1px solid lightgray;
	border-radius: 4px;
	width: 100%;
}

.addOption:focus{
    outline: 2px solid #458fff !important;
}

.TextArea:focus{
    outline: 2px solid #458fff !important;
}

.TextArea::-webkit-resizer {
    display: none;
}

.Block{
    display: block;
    width: 100%;
    overflow-x: scroll;
}
.Flex{
    border-bottom:1px solid lightgray;
    width: 150%;
}

.VariantBtn{
    border: 1px solid gray;
    border-radius: 4px;
    width: 100%;
    padding: 3px 0;
    background: transparent;
}
.Vendor{
    position: relative;

}
.VendorList {
    position: absolute;
    width: 100%;
    height: 320px;
    cursor: pointer;
    overflow-y: scroll;
    box-shadow: 0 10px 30px 0 rgba(127, 127, 127, 0.3);
    background: #fff;
    border-radius: 10px;
}

.VendorListItem{
    padding: 8px;
}
.VendorListItem:hover{
    background-color: #FAFBFB;
}

.PriceField{
    width: 48%;
	border-radius: 0.25rem;
}
/* .ProductImg{
    display: none;
    flex-wrap: wrap;
} */
.ChangeTitle{
    display: none;
}

/* .ProductImg {
    display: none;
} */
.Msg{
    width: fit-content;
    background-color: red;
    color: white;
    margin-top: 7px;
    padding: 0px 10px;
    border-radius: 4px;
}
.SalesChannel{
    margin-top:3px;
    font-size:14px;
    padding-left:5px;
}

.Platform {
    /* padding: 10px; */
    display: none;
    margin-bottom: 20px;
    border-radius: 8px;
    border: 1px solid lightgray;
}
.PlatformBox{
    display: flex;
    justify-content:space-between;
    border-bottom: 1px solid lightgray;
    padding: 10px;
}
.ErrorContent{
    border-bottom: 1px solid lightgray;
    padding: 10px 25px;
    display: none;
    justify-content: space-between;
    font-size: 15px;
}

.PlatformSpecificBtn{
    background: #FF6200;
    border-radius: 4px;
    color: white;
    border: 0;
    width: 81px;
    margin: auto;
    cursor: pointer;
}
.SkuBox{
    display: none;
    color: white;
    background-color: red;
    width: fit-content;
    padding: 5px 10px;
    margin: 20px 0px;
    border-radius: 18px;
}
.Sku{
    font-size: 14px;
    color: white;
    padding: 0px 10px;
}

.GroupNameBox{
    display: none;
    padding: 20px;
    margin: 20px 0px;
    box-shadow: 0 10px 30px 0 rgba(127, 127, 127, 0.3);
    background: #fff;
    border-radius: 10px;

}
.GroupName{
    font-size: 14px;
    padding: 0px 10px;
}

.GroupNameContent {
    display: flex;
    /* border-bottom: 1px solid lightgray; */
    padding: 5px 0px;
}

.GroupNameButtons {
    padding-top: 10px;
    display: flex;
    padding-left: 18px;
    font-size: 15px;
}

.GroupNameCreateVariant{
    display: flex;
    margin-right:20px;
}

.CreateVariant {
    border: 0px;
    background-color: #56a8c2;;
    padding: 4px 10px;
    border-radius: 4px;
    cursor: pointer;
    color: white;
}

.GroupNameTitle{
    margin-right: 10px;
    border-radius: 4px;
    border: 1px solid gray;
}

.GroupNameChangeTitle{
    border: 0px;
    background-color: gray;
    padding: 4px 10px;
    border-radius: 4px;
    cursor: pointer;
    color: white;
}


.AddProductHeader {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.5rem;
}


.AddProductHeaderContent{
    display: flex;
    align-items: center;
    gap: 1rem;
}

.AddProductBtn {
    font-size: 21px;
    font-weight: 600;
}

.Icon {
    font-size: 15px;
    padding: 5px;
    color: #005CC8;
}

.AngleDown {
    font-size: 15px;
    padding: 5px;
    color: #005CC8;
    cursor: pointer;
}

.ErrorContentBox1 {
    display: flex;
    margin: auto 0px;
}

.ErrorMessage {
    display: flex;
    color: red;
}

.AddProductContainer {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.variantField {
    display: flex;
    justify-content: space-between;
    margin-top:15px;
}

.variantRequiredField {
    color: #FF6200;
    margin-top:  2px;
    margin-left: 2px;
}

.Field {
    width: 100%;
    border-radius: 0.25rem;
}

.ImageUrl{
    text-align: center;
    border: 1px solid lightgray;
    border-radius: 4px;
    width: 100%;
}

.InputField {
    border: 1px solid lightgray;
    border-radius: 4px;
    width: 100%;
}

.variantFields {
    border: 1px solid lightgray;
    border-radius: 4px;
    width: 100%;
    padding-right:0px;
}

.TextArea {
    width: 100%;
    height: 150px;
    border: 1px solid lightgray;
    border-radius: 4px;
}

.CommonMarginTop {
    margin-top: 15px;
}

.Boxes{
    padding: 1.1rem;
    border-bottom: 1px solid lightgray;
}

.Location{
    display: flex;
    justify-content: space-between;
    font-weight: 600;
    /* margin-top: 20px; */
    /* border-bottom: 1px solid lightgray; */
    /* padding-bottom: 15px; */
}

.LocationField{
    display: flex;
    justify-content: space-between;
    padding: 1rem 0px;
}

.SaveBtn {
    border:  1px solid lightgray;
    background-color: transparent;
    border-radius: 4px;
    padding: 5px 10px;
}

.Option {
    padding: 8px;
    cursor: pointer;
    margin: 3px 0px;
}

.Variant{
    font-size:14px;
    font-weight: 600;
    display:  flex;
    color:gray;
    margin-top: 20px;
    padding: 0px 1.1rem;
    border-bottom: 1px solid lightgray;
}

.Addfile{
    margin: auto;
    position:  relative;
    top: 44%;
    width: fit-content;
    background-color: #E5EFFD;
    font-size:14px;
    color: #1F5199;
    font-weight: 600;
    padding: 2px 10px;
}



.Paragraph {
    font-Size: 14px;
    color: gray;
    margin-bottom: 10px;
}

.Sales {
    font-size:13px;
    font-weight: 600;
}

.DeselectAll {
    font-size:14px;
    color: #2C6ECB;
    width: fit-content;
}

.VendorField {
    width: 100%;
    border-radius: 4px;
    border: 1px solid #ced4da;
    font-size:14px;
}

.ShowCollections {
    display: flex;
    flex-wrap: wrap;
    font-size: 13px;
    margin-top: 5px;
}


.Collections {
    background-color: #E4E5E7;
    padding: 2px 10px;
    margin: 3px 3px;
    border-radius: 4px;
}


.ChangeTags {
    color: #2C6ECB;
    text-decoration: none;
    padding-right: 0px;
    cursor: pointer;
    margin-top: 8px;
}

.AddNewTags {
    color:  #2C6ECB;
    text-decoration: none;
    padding-right: 0px;
    cursor: pointer;
}

.SearchTagInput {
    width: 100%;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
}

.RestField{
    display: none;
}

.Continue {
    border: 0px;
    margin-top: 10px;
    border-radius: 5px;
    background: teal;
    color: white;
}

@media screen and (max-width: 1044px){
    .containBoxes{
        width: 100%;
    }
    .asideContainer{
        width: 100%;
    }
}

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.requiredField{
    color: #FF6200;
    margin-left: 2px;
    font-size: 21px;
    height: 19px;
    display: flex;
    align-items: center;
}

.vinculumRequiredField {
    color: green;
    font-size: 21px;
    height: 19px;
    display: flex;
    align-items: center;
    margin-left: 2px;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.CheckBox:checked { 
    background-color: blue;
    pointer-events: none;
}

.CheckBox[aria-disabled="true"]{
    background-color: blue;
    pointer-events: none;
}

.showLimitDiscountPop {
    background-color: red;
    padding: 0px 10px;
    border-radius: 4px;
    color: #fff;
    margin-top: 8px;
    width: fit-content;
}

.dimension {
    position: absolute;
    margin-top: -88px;
    margin-left: -20px;
    background-color: bisque;
    padding: 0px 5px;
    border-radius: 6px 0px 6px 0px;
    font-size: 12px;
    font-weight: 600;
}

.unitType {
    display: flex;
    justify-content: center;
    align-Items: center;
    padding: 10px;
    padding-top: 25px;
}

.UpdateSalesDialog {
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1300;
    position: fixed;
    top: 0%;
    width: 100%;
    left: 0%;
    bottom: 0%;
}

.SpinnerContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}
  
.Spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-left-color: #FF6200;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: spin 1s linear infinite;
}
  
@keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
}