.SignIN{
    width: 40%;
}

.Peer:first-child ~ .Peer-first\:hidden {
    display: none;
}

@media screen and (max-width: 1085px) {
    .SignIN{
        width: 50%;
    }    
}

@media screen and (max-width: 900px) {
    .SignIN{
        width: 60%;
    }    
}

@media screen and (max-width: 750px) {
    .SignIN{
        width: 75%;
    }    
}

@media screen and (max-width: 600px) {
    .SignIN{
        width: 90%;
    }    
}