.Input{
    border: 0;
    width: 100%;
    margin-top: 4px;
}

.Input:focus{
    outline: none;
}
.Button{
    background-color: transparent;
    color: rgb(129, 138, 155);
}

.Container{
    /* box-shadow: 0 10px 30px 0 rgba(127, 127, 127, 0.3); */
    background: #fff;
    border-radius: 10px;
}

.ContainerHeader{
    background-color: #243750;

    display: flex;
    color: white;
    font-size: 16px;
    font-weight: 600;
    /* border-bottom: 2px solid #000; */
}

.Customer{
    display: flex;
    font-size: 14px;
    /* border-bottom: 1px solid #ced4da; */
    /* padding: 15px 20px; */
    box-sizing: content-box !important;
    cursor: pointer;
    color:#000;
}

.Customer:hover{
    background-color: #F6F6F7;
}

.Sort{
    border: 1px solid lightgray;
    border-radius: 4px;
    color: rgb(129, 138, 155);
    padding: 6px 20px;
    height: 36px;
    display: flex;
}

.HeaderBox{
    display:flex;
    justify-content:space-between;
    /* flex-wrap: wrap; */
    padding: 20px 0px;
}

.SearchBox{
    width: 72%;
    display: flex;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    height: 40px;
    margin-right: 10px;
}

.DeleteButton button{
    border: 1px solid black;
    border-radius: 4px;
    padding: 7px 5px 6px;
    background-color: transparent;
    width: 100%;
    font-size:14px;
    color:black;
    font-weight: 600;
    font-size:15px;
    margin-right:10px;
    width: 119px;
}

.ExportButton button{
    background-color: green;
    border: 1px solid green;
    border-radius: 4px;
    padding: 7px 5px 6px;
    width: 100%;
    font-size:14px;
    color:white;
    font-weight: 600;
    font-size:15px;
    margin-right:10px;
    width: 119px;
}

.customerType {
    display: flex;
    padding: 2px 20px;
    margin-left: 40px;
    width: fit-content;
    border-radius:20px;
    font-size:14px;
}

.Dotted {
    border: 2px solid #d08d45;
    border-radius: 50%;
    height: 10px;
    margin: auto 6px auto 0;
    width: 10px;
}

.checkBox {
    border: 2px solid #8c9196;
    appearance: none;
    /* -webkit-appearance: none; */
    outline: none;
    border-radius: 4px;
}

.checkBox:checked {
    appearance: auto;
    border-radius: 2px solid #8c9196;
    clip-path: circle(65% at 50% 50%);
}

.CustomerDetail{
    display: flex;
    width: 100%;
    padding-left: 10px;
}

.CustomerName{
    display:flex;
    width: 20%;
    font-weight: 600;
    /* margin: auto */
}
.CreatedAt{
    width: 17%;
    margin: auto;
}
.CreatedBy {
    width: 10%;
}
.Email{
    width: 17%;
    margin: auto;
}
.Email p{
    padding: 2px 15px 2px 0px;
    /* margin-left: 17px */
}

.Phone{
    width: 15%;
}

.Phone p{
    padding: 2px 15px;
    margin-left: 17px
}

.CreatedByFilter{
    position: relative;
    width: 28%;
}

.searchContainer{
    display: flex;
    justify-content: space-between;
}

.Customers {
    border-radius: 0.25rem;
    /* color: #42526e; */
    cursor: pointer;
    display: flex;
    font-weight: 600;
    padding: 6px 7px;
    width: 100%;
    background-color: rgb(230, 232, 236);
    color: rgb(66, 82, 110);
}

.affiliateDeleteBtn {
    color: #dc3545 !important;
    border-color: #dc3545 !important;
    font-size: 16px !important;
    padding: 6px 20px !important;
    width: fit-content !important;
}

.affiliateCreateBtn {
    font-size: 16px !important;
    padding: 6px 20px !important;
    width: fit-content !important;
}

.addVisitCustomerBtn {
    border: 1px solid black;
    border-radius: 4px;
    padding:5px 10px;
    color: black;
    background-color: transparent;
    font-size: 14px;
    height: 32px;
    margin-right: 10px;
}

.meetingCancelled {
    background-color: #EB3A10;
    color: white;
    border-radius: 10px;
    padding: 2px 4px 0px;
    font-size: 13px;
}

.CancelMeet {
    background-color: slategray;
    color: white;
    border-radius: 10px;
    font-size: 12px;
    width: fit-content;
    padding: 0px 8px;
    margin: auto;
}

.UpdateSalesDialog {
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1300;
    position: fixed;
    top: 0%;
    width: 100%;
    left: 0%;
    bottom: 0%;
}

.salesReportHeader {
    justify-content: space-between;
    flex-wrap: wrap;
}

.Box {
    border-radius: 6px;
    box-shadow: 0 0 4px 2px hsla(0,0%,50%,.3);
    margin: 10px 0;
    padding: 15px 10px;
    font-size: 14px;
    border-left: 2px solid #243750;
}

@media only screen and (max-width: 768px) {
    .ContainerHeader{
        display: none;
    }
    .checkBox {
    }
    .DeleteButton, .ExportButton{
        margin-left: 5px;
        width: auto;
    }
    .Customer{
        flex-direction: row;
    }
    .CustomerDetail{
        flex-direction: column;
    }
    .CustomerName{
        margin-top: 4px;

        width: 100%;
    }
    .CreatedAt{
        margin-top: 4px;

        width: 100%;
    }

    .customerType {
        /* margin-top: 4px; */
        margin-left: 0px;
    }

    .Email{
        margin-top: 4px;
        display: flex;
        gap: 4px;
        width: 100%;
    }

    .Email p{
        padding: 0px 0px;
        margin-left: 0px
    }

    .Phone{
        display: flex;
        gap: 4px;
        margin-top: 4px;
        width: 100%;
    }

    .Phone p{
        padding: 0px 0px;
        margin-left: 0px
    }
    .CreatedBy {
        width: 100%;
        display: flex;
    }
    .HeaderBox {
        flex-wrap: wrap;
    }
    .SearchBox {
        width: 100%;
        margin-right: 0px;
        margin-bottom: 5px;

    }
    .CreatedByFilter {
        width: 100%;
        margin-left: 0px !important;
        margin-top: 10px;
    }

    .DeleteButton button{
        width: 70px;
    }

    .ExportButton button{
        width: 70px;
    }

    .salesReportHeader {
        justify-content: flex-end;
    }
    .ResponsiveBtn {
        margin-bottom: 10px;
    }
}

.responsiveTable, .TaskResponsiveTable  {
    display: none;
}

.responsiveTableBox {
    border-radius: 6px;
    box-shadow: 0 0 4px 2px hsla(0,0%,50%,.3);
    margin: 10px 0;
    padding: 15px 10px;
    font-size: 14px;
    border-left: 2px solid #243750;
}

.MeetingDone {
    font-size:30px;
}

.AreaFilter {
    display: none;
    padding: 10px 15px;
    position: absolute;
    background-color: #fff;
    box-shadow: 2px 2px 4px gray;
    border-radius: 0px 0px 3px 3px;
}

.ResponsiveFilter {
    display: none;
}

.PreviousAlert {
    display: none;
}

.responsiveFilterBtn {
    display: none;
    justify-content: flex-end;
}
.MobileResponsive {
    display: none;
}

@media screen and (max-width:1100px){
    .TaskHideTable {
        display: none !important;
    }

    .TaskResponsiveTable {
        display: block;
    }
}

.inventoryTabHeader {
    margin-top:-23px;
}

@media screen and (max-width:685px){
    .hideTable {
        display: none !important;
    }

    .responsiveTable {
        display: block;
    }
    .responsiveFilterBtn {
        display: flex;
    }
    .MuiTable {
        display: none;
    }
    .MobileResponsive {
        display: block;
    }
    .inventoryTabHeader {
        margin: 0px;
    }
}

@media screen and (max-width:500px){
    .responsiveTable {
        font-size: 14px;
    }
    .MeetingDone {
        font-size:25px;
    }
}