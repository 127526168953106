.hovering-list-container {
    position: relative;
}

.hovering-list {
    position: absolute;
    background-color: white;
    color: black;
    min-height: 50px;
    min-width: 250px;
    z-index: 2000;
    left: 78px;
    box-shadow: 0px 1px 9px -1px #d5d5d5;
    border-radius: 2px;
}

.Item:active {
    font-weight: 550;
}

.Item:hover {
    color: rgb(255, 98, 0);
}

.Icon:hover {
    color: rgb(255, 98, 0);
}

.child-nav-section {
    border-radius: 5px;
    background: #ffffff;
    box-shadow: inset 20px 20px 60px #efefef, inset -20px -20px 60px #ffffff;
}