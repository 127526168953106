
.Container{
    box-shadow: 0 10px 30px 0 rgba(127, 127, 127, 0.3);
    background: #fff;
    border-radius: 12px;
    padding: 10px;
    margin-top: 20px;
}

.GroupProductId {
    font-size: 21px;
    font-weight: 600;
    display: flex;
    margin-left: 5px;
}

.Button:focus{
    box-shadow: 0px 0px 4px 4px lightblue;
}

.Row{
    display: flex;
    /* padding: 18px 12px; */
    color: black;
    cursor: pointer;
    /* border-bottom: 1px solid lightgray; */
    border-radius: 6px;
    box-shadow: 0 0 4px 2px hsla(0,0%,50%,.3);
    margin: 10px 0;
    padding: 15px 10px;
    font-size: 14px;
    border-left: 2px solid #243750;
}

.Column{
    width: 11%;
}

.CreatedAtHeader {
    width: 18%;
    padding-left: 5px;
    font-size: 15px;
}

.RequestTypeHeader {
    width: 16%;
    padding-left: 10px;
    font-size: 15px;
}

.GroupId {
    font-weight:600;
	margin: auto 0px;
	padding-left: 10px;
    width: 8%;
}
.ProductDetail{
    width: 37%;
    margin: auto 0px;
    display: flex;
}
.Duration {
    display: flex;
    padding-left: 8px;
    margin: auto 0px;
    width: 12%;
}

.DurationContent {
    background-color: #df251bbe;
    width: 130px;
    padding: 0px 6px;
    border-radius: 10px;
    color: #fff;
    text-align: center;
    font-size: 12px;
}

.CreatedFrom {
    padding-left: 5px;
    /* margin: auto 0px; */
    width: 13%;
    display: flex;
}

.UpdateSalesDialog {
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1300;
    position: fixed;
    top: 0%;
    width: 100%;
    left: 0%;
    bottom: 0%;
}

.CreatedFromContent {
    padding: 0px 10px;
    color: black;
    text-align: center;
}

.DurationBox {
    padding-left:8px;
    margin: auto 0px;
    width: 14%;
}

.Platforms{
    width: 18%;
    margin: auto 0px;
}

.GroupPlatforms {
    width: 12%;
    margin: auto 0px;
}

.PlatformHeader{
    border-bottom: 1px solid gray;
    text-align: center;
}
.PlatformsType{
    display: flex;
    justify-content: space-between;
}

.GroupPlatforms>div {
    margin-bottom: 5px;
}

.Reports {
    display:flex;
    justify-content: space-between;
    width:16%;
    padding-left:10px;
}

.Total {
    text-align: center;
    background-color: lightgray;
    padding: 0px 10px;
    border-radius: 10px;
}

.Success {
    background-color: #AEE9D1;
    padding: 0px 10px;
    border-radius: 10px;
    text-align: center;
}

.Failed {
    background-color: #FED3D1;
    padding: 0px 10px;
    border-radius: 10px;
    text-align: center;
}

.FailedStatus {
    background-color: #D30038;
    color:#fff;
    width: 76px;
    text-align: center;
}

.SuccessStatus {
    background-color: lightgreen;
}

.PlatformBox {
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
}

.StatusContent {
    margin-right:10px;
    font-size: 1rem;
    font-weight: 600;
}

.StatusBox {
    display: flex;
	justify-content: space-between;
}

.Box{
    box-shadow: 0 10px 30px 0 rgba(127, 127, 127, 0.3);
    background: #fff;
    border-radius: 5px;
    padding: 20px;
    margin:20px 0px;
}
.Status{
    /* background-color: lightgreen; */
    padding: 0px 10px;
    border-radius: 10px;
}
.CompleteOrder{
    border: 0;
    background-color: #008060;
    color: white;
    border-radius: 4px;
    width: 100px;
}

.ProductIdContainer{
    width: 82%;
    margin: auto;
}

.RequestType {
    width: 75%;
    text-align: center;
    background-color: lightgreen;
    padding: 5px 10px;
    border-radius: 12px;
    font-size: 12px;
}

.DeleteBtn {
    border:  1px solid black;
    background-color: transparent;
    border-radius: 4px;
    padding: 5px 10px;
}
.Sku{
    font-weight:600;
	margin: auto 0px;
	width:21.3%;
    display: flex;
}

.CreatedAt{
    width:16%;
    margin: auto 0px;
}

.Request{
    padding-left: 10px ;
    width:12%;
    display: flex;
    margin: auto 0px; 
}
.Header {
    display:flex;
}
.CreatedBy {
    width:11%;
    display:flex;
    justify-content: center;
}

.GroupDetailsHeader {
    display:flex;
    padding:12px 12px 5px 12px;
    background-color:#243750;
    color:#fff;
    border-radius: 4px 4px 0px 0px;
}

.RequestId {
    width: 9%;
    display: flex;
}

.Database {
    width:22%;
    text-align:center;
    display:flex;
}

.UploadType {
    width: 11%;
    display: flex;
}
@media only screen and (max-width: 768px) {
    .Row{
        flex-direction: column;
        /* padding: 12px 8px; */
    }
    .Header {
        display: none;
    }
    .GroupId {
        width: 100%;
    }
    .RequestId{
        /* padding-left: 4px; */
        width: 100%;
    }
    .Sku{
        margin-top: 4px;
        width: 100%;
    }
    .ProductDetail{
        margin-top: 4px;
        width: 100%;
    }
    .CreatedAt{
        margin-top: 4px;
        display: flex;
        width: 100% !important;
    }
    .Request{
        margin-top: 4px;
        width: 100%;
    }
    .UploadType {
        width:100%;
    }
    .CreatedBy{
        justify-content: unset;
        padding-left: 5px;
        width: 100%;
    }
    .Platforms{
        margin-top: 10px;
        width: 100%;
    }
    .CreatedFrom {
        width: 100%;
    }
    .Reports {
        width: 50%;
    }
    .GroupDetailsHeader {
        display: none;
    }
    .Database {
        width:100%;
    }
}