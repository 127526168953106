.productCardGreen {
    border-bottom: green 5px solid;
}
    
.productCardRed {
    border-bottom: red 5px solid;
}

.productCardYellow {
    border-bottom: yellow 5px solid;
}