.modal {
    position: relative;
    top: 40%;
    right: 10%;
    bottom: 1%;
    left: 3%;
    box-shadow: 0 10px 30px 0 rgba(127, 127, 127, 0.3);
    background: #fff;
    border-radius: 10px;
    z-index: 1000;
    overflow-y: scroll;
    font-size: 14px;
    padding: 5px;
    width: 92%;
}
.styles{
    padding: 10px 15px;
    border-radius: 10px;
    margin: 5px 0px;
    cursor: pointer;
}
.styles:hover{
    background-color: #F6F6F7;
}
.ContactSearch{
    padding: 10px;
    background: #F2F7FE;
    border: 2px solid #458FFF;
    border-radius: 10px;
    display:flex;
    cursor: pointer;
}

.UpdateSalesDialog {
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1300;
    position: fixed;
    top: 0%;
    width: 100%;
    left: 0%;
    bottom: 0%;
}