.modal {
    position: absolute;
    right: 10%;
    height: 448px;
    left: 2%;
    box-shadow: 0 10px 30px 0 rgba(127, 127, 127, 0.3);
    background: #fff;
    border-radius: 10px;
    z-index: 1000;
    overflow-y: scroll;
    font-size: 14px;
    padding: 5px;
    width: 96%;
}

.confirmModal {
    position: fixed;
    top: 5%;
    right: 35%;
    bottom: 8%;
    left: 35%;
    box-shadow: 0 10px 30px 0 rgba(127, 127, 127, 0.3);
    background: #fff;
    border-radius: 10px;
    z-index: 1000;
}

.BoxContent{
    display: flex;
    justify-content: space-between;
    font-size: 20px;
    padding: 20px 25px;
}

.styles{
    padding: 10px 15px;
    border-radius: 10px;
    margin: 5px 0px;
    cursor: pointer;
}
.styles:hover{
    background-color: #F6F6F7;
}
.ContactSearch{
    padding: 10px;
    background: #F2F7FE;
    border: 2px solid #458FFF;
    border-radius: 10px;
    display:flex;
    cursor: pointer;
}

.CancelButton {
    background-color:#C1C1C1;
    border: 0px;
    border-radius: 4px;
    color: #fff;
    font-size: 20px;
    padding: 6px;
    width: 120px;
}

.confirmPopupBtn {
    display: flex;
    justify-content: space-between;
    padding: 15px;
    margin: 0px auto;
    width: 290px;
}

.confirmPopupBox {
    text-align: center;
    margin: 30px 0px;
}

.XMark {
    font-size: 75px;
    color: #F37171;
    cursor: pointer;
}

.colorTextAlign {
    color: #808080;
    text-align: center;
}

@media screen and (max-width:1000px) {
    .confirmModal{
        right: 20%;
        left: 20%;
    }
}
@media screen and (max-width:900px) {
    .confirmModal{
        right: 15%;
        left: 15%;
    }
}


@media screen and (max-width:600px) {
    .confirmModal{
        right: 10%;
        left: 10%;
    }
}


@media screen and (max-width:400px) {
    .confirmModal{
        right: 3%;
        left: 3%;
    }
    .XMark {
        font-size: 60px;
    }
}