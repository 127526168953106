.addButton {
  background-color: #1976d2;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
  text-transform: none;
}

.modalContainer {
  max-width: 100vw;
  max-height: 100vh;
  display: flex;
  justify-content: center;
  overflow: scroll;
}

.container {
  background-color: #ffffff;
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding: 1.25rem;
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: fit-content;
}
.childContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1rem;
}

.selectContainer {
  flex: 1;
  justify-content: flex-start;
  margin-bottom: 1rem;
}

.btn {
  background-color: #f56565;
  border-radius: 0.25rem;
  color: white;
  font-size: 1rem;
  font-weight: bold;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  width: 100%;
}

.btn:hover {
  background-color: #c53030;
}

.btnContainer {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.options {
  font-size: 0.875rem;
}

.storeHeader {
  color: #153750;
  font-size: 2.25rem;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.storeHeaderiPhoneX {
  font-size: 1rem;
  color: #153750;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.storeHeaderLine1 {
  width: 16rem;
  height: 0.25rem;
  background-color: #f46f2c;
  margin: 2px auto;
}
.storeHeaderLine1iPhoneX {
  width: 8rem;
  height: 2px;
  margin: 1px auto;
  background-color: #f46f2c;
}
.storeHeaderLine2 {
  width: 15rem;
  height: 2px;
  background-color: #f46f2c;
}
.storeHeaderLine2iPhoneX {
  width: 7rem;
  height: 1px;
  background-color: #f46f2c;
}

.storeGridContainer {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 1rem;
  justify-content: center;
  align-items: center;
  padding-top: 2rem;
  padding-bottom: 2rem;
  color: #14364f;
  @media (max-width: 768px) {
    gap: 2rem;
  }
}
.storeGridContaineriPhoneX {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 2rem;
  justify-content: center;
  align-items: center;
  padding-top: 2rem;
  padding-bottom: 2rem;
  color: #14364f;
}

.storeGridItem {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1.5rem;
  cursor: pointer;
  @media (max-width: 768px) {
    font-size: 0.875rem;
  }
}
.storeGridItemiPhoneX {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 0.875rem;
  cursor: pointer;
}

.storeGridItemImage {
  width: auto;
  height: 3rem;
  margin: auto;
  @media (max-width: 768px) {
    height: 1.5rem;
  }
}
.storeGridItemImageiPhoneX {
  width: auto;
  height: 1.5rem;
  margin: auto;
}
.storeGridItemActive {
  color: #f46f2c;
}

.storeDetailContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
  @media (max-width: 768px) {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
}
.storeDetailContaineriPhoneX {
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.storeDetailImageContainer {
  display: flex;
  justify-content: center;
  width: 50%;
  height: 24rem;
  padding-left: 3rem;
  padding-right: 3rem;
}
.storeDetailImageContaineriPhoneX {
  display: flex;
  justify-content: center;
  width: auto;
  max-height: 16rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.storeDetailImage {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.storeDetailTextContainer {
  padding: 2rem;
  display: flex;
  justify-content: center;
  width: 50%;
  @media (max-width: 768px) {
    padding: 1rem;
    width: auto;
  }
}
.storeDetailTextContaineriPhoneX {
  padding: 1rem;
  width: auto;
  display: flex;
  justify-content: center;
}

.storeDetailText {
  padding: 1rem;
  text-align: center;
  @media (max-width: 768px) {
    padding: 0.5rem;
  }
}
.storeDetailTextiPhoneX {
  padding: 1rem;
  text-align: center;
  padding: 0.5rem;
}

.storeDetailTitle {
  font-size: 1.5rem;
  color: #f46f2c;
  font-weight: 600;
  margin-bottom: 1rem;
  @media (max-width: 768px) {
    font-size: 1.25rem;
  }
}
.storeDetailTitleiPhoneX {
  font-size: 0.875rem;
  color: #f46f2c;
  font-weight: 600;
  margin-bottom: 1rem;
}
.storeDetailAddress {
  font-size: 1.125rem;
  line-height: 1.75rem;
  color: #14364f;
}
.storeDetailAddressiPhoneX {
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #14364f;
}

@media (min-width: 640px) {
  .btn {
    width: 50%;
  }

  .selectContainer {
    margin-bottom: 0;
  }

  .childContainer {
    flex-direction: row;
  }

  .storeText {
    font-size: 1rem;
  }
}
.swiperParent {
  padding: 1rem 1rem;
  width: 100%;
  align-self: center;
}
.sliderImg {
  height: auto;
  width: 100%;
}
@media (min-width: 768px) {
  .btn {
    font-size: 1.125rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .options {
    font-size: 1rem;
  }
}
@media (max-width: 768px) {
  .storeHeaderLine1 {
    width: 8rem;
    height: 2px;
    margin: 1px auto;
  }

  .storeHeaderLine2 {
    width: 7rem;
    height: 1px;
  }

  .storeHeader {
    font-size: 1.25rem;
  }
}
