.file-input{
    border: 2px solid #FF7E2D;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 0.5em;
    background-color: #FFE2D1;
}

.alerts-container{
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
}