.Button:focus{
    box-shadow: 0px 0px 4px 4px lightblue;
}

.Row{
    display: flex;
    border-radius: 6px;
    box-shadow: 0 0 4px 2px hsla(0,0%,50%,.3);
    margin: 10px 0;
    padding: 15px 10px;
    font-size: 14px;
    border-left: 2px solid #243750;
    cursor: pointer;
}

.Row:hover{
    background-color: #F6F6F7;
}

.searchContainer{
    display: flex;
    justify-content: space-between;
}

.searchField{
    width: 98%;
    padding: 0.375rem 0.75rem 0.375rem 4px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.6;
    color: #212529;
    background-clip: padding-box;
    appearance: none;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    border: 0;
    background-color: transparent;
}
.searchField:focus{
    outline: none;
}

.Column{
    width: 20%;
}

.DateColumn{
    width: 25%;
}

.CustomerColumn{
    width: 24%;
}

.CreatedBy{
    width: 20%;
}
.TotalPrice{
    width: 11%;
}
.Customers{
    width: 100%; 
    display: flex;
    border-radius: 0.25rem;
    color:#42526E;
    background-color: #e6e8ec;
    padding:7px;
    cursor:pointer;
    font-weight:600;
}

.Buttons{
    display: none;
}

.showButtons{
    border: 0;
    padding: 5px 10px;
    border-radius: 7px;
    background-color: #253858;
    color: white;
}

.taxModal {
    position: fixed;
    top: 5%;
    right: 25%;
    bottom: 8%;
    left: 25%;
    box-shadow: 0 10px 30px 0 rgba(127, 127, 127, 0.3);
    background: #fff;
    border-radius: 10px;
    z-index: 1000;
}

.Box{
    border-bottom: 1px solid #ced4da;
}

.BoxContent{
    display: flex;
    justify-content: space-between;
    font-size: 20px;
    padding: 20px 25px;
}

.Button button{
    background: #fafbfb;
    border: 1px solid #d2d5d8;
    padding: 5px 15px;
    border-radius: 4px;
    color: #8c9196;
}

.Responsive{
    display: none;
}

.SearchDraftOrder{
    width: 72%;
}

.CreatedByFilter{
    position: relative;
    width: 28%;
}

.draftOrderModal {
    padding: 10px 20px;
    font-size: 14px;
    overflow-y: auto;
    height: 64%;
}

.TotalTax {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    font-weight: 600;
}

.TotalTaxBottom {
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid #d2d5d8;
    padding: 15px 20px;
}

.TaxRateCloseBtn {
    background-color: transparent; 
    color: #000;
    font-size:15px;
    padding: 6px 15px;
}

.TaxRateCloseIcon {
    font-size: 25px;
    color: rgb(129, 138, 155);
    cursor: pointer;
}

.NoDraftOrderExist {
    position: absolute;
    left: 41%;
    color: red;
    margin-top: 10%;
}

@media screen and (max-width:1085px) {
    .taxModal{
        right: 20%;
        left: 20%;
    }
}

@media screen and (max-width:1000px) {
    .taxModal{
        right: 15%;
        left: 15%;
    }
}
@media screen and (max-width:900px) {
    .taxModal{
        right: 10%;
        left: 10%;
    }
}

@media screen and (max-width:750px) {
    .taxModal{
        right: 5%;
        left: 5%;
    }
    .SearchDraftOrder{
        width: 65%;
    }
    
    .CreatedByFilter{
        position: relative;
        width: 35%;
    }
}

@media screen and (max-width:650px) {
    .Column{
        width: 85%;
    }
    .CreatedBy{
        display: none;
    }
    .DateColumn{
        display: none;
    }
    .CustomerColumn{
        display: none;
    }
    .Responsive{
        display: block;
    }    
}


@media screen and (max-width:600px) {
    .taxModal{
        right: 2%;
        left: 2%;
    }

    .SearchDraftOrder{
        width: 60%;
    }
    
    .CreatedByFilter{
        position: relative;
        width: 40%;
    }
}

@media screen and (max-width: 500px) {
    .SearchDraftOrder{
        width: 100%;
    }
    
    .CreatedByFilter{
        position: relative;
        margin-top: 10px;
        width: 98%;
    }
}
