/* customer.module.css */
.fullWidth {
  width: 100%;
}

.itemsCenter {
  align-items: center;
}

.gridLayout {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 1.5rem;
}

@media (min-width: 768px) {
  .gridLayout {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 2rem;
  }
}

.flexRow {
  display: flex;
}

.gapX2 {
  gap: 0.5rem;
}

.gapX6 {
  gap: 1.5rem;
}

.twoThirdsWidth {
  width: 66.666667%;
}

.oneFourthWidth {
  width: 25%;
}

.selfBaseline {
  align-self: baseline;
}

.spaceY2 {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.height4 {
  height: 1rem;
}

.width4 {
  width: 1rem;
}

.marginLeft2 {
  margin-left: 0.5rem;
}

.marginTop2 {
  margin-top: 0.5rem;
}

.flexCenter {
  display: flex;
  justify-content: center;
}

.halfWidth {
  width: 50%;
}
.cardContainer {
  max-width: 100%;
  margin: auto;
  box-shadow: 0.2px 0.4px 6px 8px rgba(0, 0, 0, 0.2);
  padding: 20px;
  box-sizing: border-box;
  border-radius: 20px;
}

@media (max-width: 600px) {
  .cardContainer {
    padding: 10px;
  }
}

.Images {
    width: 40px;
    height: 40px;
    margin: 0px 10px;
    border: 1px solid #ced4da;
    border-radius: 4px;
}