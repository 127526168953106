.margin-right{
    margin-right: 10px;
}
.margin-top-10{
    margin-top: 10px;
}

.custom-btn{
    border: none;
    outline: none;
    box-shadow: none;
    background-color: #ff6200;
    padding: 0.5em 1em;
    color:white;
    margin: 10px;
    border-radius: 10px;
}

.ImagesBox {
    background-color: #FFE2D1;
    padding: 10px 0px;
    border-radius: 10px;
    /* border: 2px solid black; */
    margin-bottom: 20px;
    border: 2px solid #FF7E2D;
}

.image-grid{
    display: grid;
    grid-template-columns: repeat(4,1fr);
    gap: 0.5em;
}

.grid-image-item{
    width: 100%;
    border-radius: 1em;
    border: 2px solid rgb(125, 125, 125);
}

.uploadedImages{
    width: 100%;
    border-radius: 1em;
}

.success-message{
    text-align: center;
    background-color: #ff6200;
    color: white;
    border-radius: 1em;
    margin-bottom: 1em;
    padding: 0.5em;
}

.success-page{
    width: 100%;
}

.folder-structure{
    width: 100%;
    height: 50vh;
}

@media screen and (max-width:768px){
    .btnBetween {
        justify-content: space-between;
    }
    .buttonResponsive {
        font-size: 13px;
        padding: 4px 10px;
    }
    .formInput {
        font-size: 14px
    }
    .mgRight {
        margin-right: 10px;
    }
}