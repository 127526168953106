.Grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    gap: 2rem;

    .Span {
        grid-row: 2;
        grid-column: 1 / span x 2;
    }
}