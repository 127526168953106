.container {
  margin: 0 auto;
  max-width: 66.375rem;
}
.mainContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  font-size: 14px;
}
.containBoxes {
  width: 66%;
  margin-bottom: 15px;
}
.containerBox {
  padding: 1.1rem;
}
.Box {
  width: 100%;
  border-radius: 8px;
  margin-bottom: 1.25rem;
  padding: 1.1rem;
  box-shadow: 0 0 0.3152rem rgba(0, 0, 0, 0.181),
    0 0 0.3152rem rgba(0, 0, 0, 0.181);
}

.BoxHistory {
  width: 130%;
  border-radius: 8px;
  margin-bottom: 1.25rem;
  padding: 1rem;
  box-shadow: 0 0 0.3152rem rgba(0, 0, 0, 0.181),
    0 0 0.3152rem rgba(0, 0, 0, 0.181);
}

.UpdateSalesDialog {
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1300;
  position: fixed;
  top: 0%;
  width: 100%;
  left: 0%;
  bottom: 0%;
}

.Pricing {
  width: 100%;
  border-radius: 8px;
  margin-bottom: 1.25rem;
  box-shadow: 0 0 0.3152rem rgba(0, 0, 0, 0.181),
    0 0 0.3152rem rgba(0, 0, 0, 0.181);
}

.searchField {
  width: 98%;
  padding: 0.3rem 0.75rem 0.3rem 4px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-clip: padding-box;
  appearance: none;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  border: 0;
  background-color: transparent;
}
.qty {
  width: 98%;
  padding: 0.3rem 0 0.3rem 4px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-clip: padding-box;
  appearance: none;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  border: 0;
  background-color: transparent;
}
.qty:focus {
  outline: 2px solid #458fff !important;
}
.searchField:focus {
  outline: 2px solid #458fff !important;
}

.imgInput {
  width: 98%;
  padding: 0.3rem 0.75rem 0.3rem 4px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-clip: padding-box;
  appearance: none;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  border: 0;
  background-color: transparent;
}
.imgInput:hover {
  outline: 2px solid #458fff !important;
}
/* .Label{ 
    height: 120px;
    border: 1px dotted gray;
    width: 100%;
    border-radius: 4px;
  } */

/* .Label:hover{
    background-color: #FAFBFB;
  } */

.Option {
  padding: 1.1rem 2rem;
  display: flex;
}
.DiscountBox {
  display: flex;
  padding-top: 5px;
  font-size: 14px;
}
.Discount {
  background: greenyellow;
  padding: 0px 10px;
  margin-left: 5px;
  border-radius: 4px;
}

.TrashIcon:hover {
  color: #000000 !important;
}
.asideContainer {
  width: 32%;
}
.OptionName {
  position: absolute;
  left: 0%;
  box-shadow: 0 10px 30px 0 rgba(127, 127, 127, 0.3);
  background: #fff;
  border-radius: 10px;
  z-index: 1000;
  font-size: 14px;
  padding: 5px;
  width: 100%;
  margin-top: 4px;
  display: none;
}

.OptionValue {
  position: relative;
  left: 0%;
  background: #fff;
  border-radius: 10px;
  z-index: 1000;
  font-size: 14px;
  width: 100%;
  margin-top: 4px;
  /* display: none; */
}

.addOption {
  width: 98%;
  padding: 0.3rem 0.75rem 0.3rem 4px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-clip: padding-box;
  appearance: none;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  border: 0;
  background-color: transparent;
  border: 1px solid lightgray;
  border-radius: 4px;
  width: 100%;
}

.addOption:focus {
  outline: 2px solid #458fff !important;
}

.TextArea:focus {
  outline: 2px solid #458fff !important;
}

.TextArea::-webkit-resizer {
  display: none;
}

.Block {
  display: block;
  width: 100%;
  overflow-x: scroll;
}
.Flex {
  border-bottom: 1px solid lightgray;
  width: 150%;
}

.VariantBtn {
  border: 1px solid gray;
  border-radius: 4px;
  width: 100%;
  padding: 3px 0;
  background: transparent;
}
.Vendor {
  position: relative;
}
.VendorList {
  position: absolute;
  width: 100%;
  height: 320px;
  cursor: pointer;
  overflow-y: scroll;
  box-shadow: 0 10px 30px 0 rgba(127, 127, 127, 0.3);
  background: #fff;
  border-radius: 10px;
}

.VendorListItem {
  padding: 8px;
}
.VendorListItem:hover {
  background-color: #fafbfb;
}

.PriceField {
  width: 48%;
  border-radius: 0.25rem;
}
/* .ProductImg{
    display: none;
  } */
.ChangeTitle {
  display: none;
}

/* .ProductImg {
    display: none;
  } */
.Msg {
  width: fit-content;
  background-color: red;
  color: white;
  margin-top: 7px;
  padding: 0px 10px;
  border-radius: 4px;
}
.SalesChannel {
  margin-top: 3px;
  font-size: 14px;
  padding-left: 5px;
}

.Platform {
  /* padding: 10px; */
  display: none;
  margin-bottom: 20px;
  border-radius: 8px;
  border: 1px solid lightgray;
}
.PlatformBox {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid lightgray;
  padding: 10px;
}
.ErrorContent {
  border-bottom: 1px solid lightgray;
  padding: 10px 25px;
  display: none;
  justify-content: space-between;
  font-size: 15px;
}

.PlatformSpecificBtn {
  background: #ff6200;
  border-radius: 4px;
  color: white;
  border: 0;
  width: 81px;
  margin: auto;
  cursor: pointer;
}
.SkuBox {
  display: none;
  background-color: red;
  width: fit-content;
  padding: 0px 10px;
  margin: 20px 0px;
}
.Sku {
  font-size: 14px;
  color: white;
  padding: 0px 10px;
}

.GroupNameBox {
  display: none;
  padding: 20px;
  margin: 20px 0px;
  box-shadow: 0 10px 30px 0 rgba(127, 127, 127, 0.3);
  background: #fff;
  border-radius: 10px;
}
.GroupName {
  font-size: 14px;
  padding: 0px 10px;
}

.GroupNameContent {
  display: flex;
  border-bottom: 1px solid lightgray;
  padding: 5px 0px;
}

.GroupNameButtons {
  padding-top: 20px;
  display: flex;
}

.GroupNameCreateVariant {
  display: flex;
  margin-right: 20px;
}

.CreateVariant {
  border: 0px;
  background-color: #f0f0f0;
  padding: 4px 10px;
  border-radius: 4px;
  cursor: pointer;
}

.GroupNameTitle {
  margin-right: 10px;
  border-radius: 4px;
  border: 1px solid gray;
}

.GroupNameChangeTitle {
  border: 0px;
  background-color: #f0f0f0;
  padding: 4px 10px;
  border-radius: 4px;
  cursor: pointer;
}

.AddProductHeader {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.5rem;
}

.AddProductHeaderContent {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.AddProductBtn {
  font-size: 21px;
  font-weight: 600;
}

.Icon {
  font-size: 15px;
  padding: 5px;
  color: #005cc8;
}

.AngleDown {
  font-size: 15px;
  padding: 5px;
  color: #005cc8;
  cursor: pointer;
}

.ErrorContentBox1 {
  display: flex;
  margin: auto 0px;
}

.ErrorMessage {
  display: flex;
  color: red;
}

.AddProductContainer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.variantField {
  display: flex;
  justify-content: space-between;
  /* margin-top:15px; */
}

.variantRequiredField {
  color: #ff6200;
  margin-top: 2px;
  margin-left: 2px;
}

.Field {
  width: 100%;
  border-radius: 0.25rem;
}

.ImageUrl {
  text-align: center;
  border: 1px solid lightgray;
  border-radius: 4px;
  width: 100%;
}

.InputField {
  border: 1px solid lightgray;
  border-radius: 4px;
  width: 100%;
}

.variantFields {
  border: 1px solid lightgray;
  border-radius: 4px;
  width: 100%;
  padding-right: 0px;
}

.TextArea {
  width: 100%;
  height: 150px;
  border: 1px solid lightgray;
  border-radius: 4px;
}

.CommonMarginTop {
  margin-top: 15px;
}

.Boxes {
  padding: 1.1rem;
  border-bottom: 1px solid lightgray;
}

.Location {
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  /* margin-top: 20px; */
  /* border-bottom: 1px solid lightgray; */
  /* padding-bottom: 15px; */
}

.LocationField {
  display: flex;
  justify-content: space-between;
  padding: 1rem 0px;
}

.DeleteBtn {
  border: 1px solid #db3c1f;
  background-color: transparent;
  border-radius: 4px;
  padding: 5px 10px;
  font-weight: 600;
  color: #db3c1f;
  font-size: 15px;
}

.UpdateBtn {
  border: 1px solid black;
  background-color: transparent;
  border-radius: 4px;
  padding: 5px 10px;
  font-weight: 600;
  font-size: 15px;
  /* color: ; */
}

.Option {
  padding: 8px;
  cursor: pointer;
  margin: 3px 0px;
}

.Variant {
  font-size: 14px;
  font-weight: 600;
  display: flex;
  color: gray;
  margin-top: 20px;
  padding: 0px 1.1rem;
  border-bottom: 1px solid lightgray;
}

.Addfile {
  margin: auto;
  position: relative;
  top: 44%;
  width: fit-content;
  background-color: #e5effd;
  font-size: 14px;
  color: #1f5199;
  font-weight: 600;
  padding: 2px 10px;
}

.Paragraph {
  font-size: 14px;
  color: gray;
  margin-bottom: 10px;
}

.Sales {
  font-size: 13px;
  font-weight: 600;
}

.DeselectAll {
  font-size: 14px;
  color: #2c6ecb;
  width: fit-content;
}

.VendorField {
  width: 100%;
  border-radius: 4px;
  border: 1px solid #ced4da;
  font-size: 14px;
}

.ShowCollections {
  display: flex;
  flex-wrap: wrap;
  font-size: 13px;
  margin-top: 5px;
}

.Collections {
  background-color: #e4e5e7;
  padding: 2px 10px;
  margin: 3px 3px;
  border-radius: 4px;
}

.ChangeTags {
  color: #2c6ecb;
  text-decoration: none;
  padding-right: 0px;
  cursor: pointer;
  margin-top: 8px;
}

.AddNewTags {
  color: #2c6ecb;
  text-decoration: none;
  padding-right: 0px;
  cursor: pointer;
}

.SearchTagInput {
  width: 100%;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.showDeleteButton {
  width: 30px;
  height: 30px;
  position: absolute;
  left: 84%;
  padding: 1px 1px 0px 0px;
  cursor: pointer;
}

.thresholdPrice {
  background-color: red;
  padding: 0px 10px;
  border-radius: 4px;
  color: #fff;
  margin-top: 8px;
  width: fit-content;
}
.hidePopUp {
  display: none;
}

.CheckBox:checked {
  background-color: blue;
  pointer-events: none;
}

.CheckBox[aria-disabled="true"] {
  background-color: blue;
  pointer-events: none;
}

.SalesChannel {
  margin-top: 3px;
  font-size: 14px;
  padding-left: 5px;
}

.requiredField {
  color: #ff6200;
  margin-left: 2px;
  font-size: 21px;
  height: 19px;
  display: flex;
  align-items: center;
}

.vinculumRequiredField {
  color: green;
  font-size: 21px;
  height: 19px;
  display: flex;
  align-items: center;
  margin-left: 2px;
}

.dimension {
  position: absolute;
  margin-top: -88px;
  margin-left: -20px;
  background-color: bisque;
  padding: 0px 5px;
  border-radius: 6px 0px 6px 0px;
  font-size: 12px;
  font-weight: 600;
}

/* Product History CSS */
tMain {
  margin: 0;
  padding: 0;
  font-family: san-serif;
  box-sizing: border-box;
}
.timeLine {
  position: relative;
  max-width: 1000px;
  margin: 10% auto;;
}
.container {
  padding: 0.5rem 1.5rem;
  position: relative;
  min-height: 1.2rem;
  margin-bottom: 2%;
}
.textBox {
  padding: 10px 10px;
  position: relative;
  font-size: 15px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.iconC {
  position: absolute;
  width: 20px;
  left: -4.5%;
  top: 3;
  z-index: 10;
}

.timeLine::after {
  content: "";
  position: absolute;
  width: 1.8px;
  height: 155%;
  background-color: rgb(255, 98, 0);
  top: -10px;
  left: 7.5%;
  margin-left: -3px;
  z-index: -1;
}
.dateC {
  padding: 0px 40px;
  position: relative;
  width: 40%;
  font-family: san-serif;
}
.whole {
  margin: -5% 0;
}
.plus {
  width:20px;
  top: -10%;
  left: 7.5%;
  display : inline;
}


@media (min-width: 576px) { 
  .timeLine {
    margin: 20px auto;
  }
  .container {
    width: 90%;
  }
}


@media (min-width: 768px) { 
  .timeLine {
    margin: 30px auto;
  }
  .container {
    width: 80%;
  }
}

@media (min-width: 992px) { 
  .timeLine {
    margin: 50px auto;
  }
  .container {
    width: 97%;
  }
}
@media screen and (max-width: 1044px) {
  .containBoxes {
    width: 100%;
  }
  .asideContainer {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .AddProductBtn {
    font-size: 16px;
    margin-bottom: 10px;
  }
  .UpdateBtn,
  .DeleteBtn {
    font-size: 13px;
  }
}
