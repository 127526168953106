.MuiSelect-select {
    min-height: 12px !important;
}

.MuiPopover-paper {
    min-width: 255px !important;
}

.Mui-focused {
    top: 3% !important;
}
/* .MuiOutlinedInput-root {
    padding: 6px !important;
} */