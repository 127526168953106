.Container{
    background: #fff;
    border-radius: 10px;
}

.HeaderBox{
    display:flex;
    justify-content:space-between;
    /* flex-wrap: wrap; */
    padding: 20px 0px;
}

.SearchBox{
    display: flex;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    height: 40px;
    margin-bottom: 0.3em;
    margin-right: 1em;
}

.CreatedByFilter{
    position: relative;
    width: 28%;
}

/* By default mobile responsive table will be hidden */
.MobileResponsiveTable { 
    display: none;
}

/* Hide Desktop responsive table and display mobile responsive table */
@media screen and (max-width:540px){
    .DesktopResponsiveTable {
        display: none;
    }
    .MobileResponsiveTable {
        display: block;
    }
}